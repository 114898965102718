<script>
import { EventBus } from "@/event-bus.js"
export default {
  props: {
    event: {
      type: Object,
    },
    isDaySelected: {
      type: Boolean,
    },
  },
  methods: {
    // TODO: Use isDaySelected prop if we want to implement any logic for present day in the calendar.
    showReportDetails() {
      if (this.event.type === "plot") {
        EventBus.$emit("showPlotsForSelectedDate", this.event)
      } else if (this.event.type === "station_report") {
        EventBus.$emit("showStationReportsForSelectedDate", this.event)
      } else {
        EventBus.$emit("showReportsForSelectedDate", this.event)
      }
    },
  },
}
</script>
<template>
  <div class="panel no-margin" :class="[event.color]" @click="showReportDetails">
    <div 
      id="eventName" 
      class="panel-heading event-title" 
      :class="{'clickable-event':isDaySelected}"
    >
      {{event.title}} {{ event.count ? '('+event.count+')' : ''}}
    </div>
  </div>
</template>
<style>
.event-title {
    padding: 0px 5px;
    font-size: 14px;
    color: #fff;
}
.event-title:focus {
    outline: auto !important;
}
.clickable-event {
    /* text-decoration: underline; */
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}
</style>
