<script>
import store from "@/store/index.js"
import { mapState, mapGetters } from "vuex"
import { EventBus } from "@/event-bus.js"
import Calendar from "@/components/CalendarMonth"
import ResultsContainer from "@/components/ResultsContainer"
import PlotResults from "@/components/PlotResults"
import PlotFilter from "@/components/CalendarPlotFilter"
import Spinner from "@/components/Spinner"
import axios from "axios"

export default {
  components: {
    ResultsContainer,
    PlotResults,
    Calendar,
    Spinner,
    PlotFilter,
  },
  data: () => ({
    reportsCurrentMonth: [],
    plotsCurrentMonth: [],
    recentDate: null,
    availableReports: [],
    availableReportsFields: [
      { key: "reportId", label: "ID", sortable: true },
      { key: "shortDescription", label: "Short Description", sortable: true },
      { key: "submitter", label: "Submitter", sortable: true },
      { key: "reportDate", label: "Report Date", sortable: true },
      { key: "updated", label: "Report Updated" },
      { key: "action", label: "Action" },
    ],
    events: [],
    stationReports: [],
    modalDetails: "",
  }),
  computed: {
    ...mapGetters("global", ["getCampaignId", "getCampaignAcronym", "getCampaignSiteCode"]),
    ...mapState("plots", ["plots", "loadingPlots"]),
    filterPlots() {
      const groupPlots =
        this.plots && this.plots.length > 0
          ? this.plots.reduce((r, a) => {
            r[a.node.plotName] = [...(r[a.node.plotName] || []), a]
            return r
          }, {})
          : []
      return groupPlots
    },
  },
  created () {
    const self = this
    EventBus.$on("updateCalendarEvents", (payload) => {
      self.calendarContextUpdated(payload)
    })
    EventBus.$on("showReportsForSelectedDate", (payload) => {
      self.modalDetails = payload
      self.calendarReportsSelected(payload.date, payload.type)
    })
    EventBus.$on("showPlotsForSelectedDate", (payload) => {
      self.modalDetails = payload
      self.calendarPlotsSelected(payload.date)
    })
    EventBus.$on("showStationReportsForSelectedDate", (payload) => {
      self.modalDetails = payload
      self.$bvModal.show("availableStationReport")
    })
    
  },
  mounted() {
    store.dispatch("reports/getRecentReportForCampaign", this.getCampaignId).then(response => {
      const reportDate = response?.data?.data?.allCatalogReports?.edges?.[0]?.node?.reportDate
      this.recentDate = this.$moment?.utc(reportDate)?.format("YYYY-MM-DD")
      this.calendarContextUpdated(this.recentDate)
    })
  },
  methods: {
    getShortDescription(node) {
      if (node.shortDescription && node.shortDescription !== "undefined") {
        return node.shortDescription.length > 50? node.shortDescription.substring(0, 50) + "..." : node.shortDescription
      } else if (node.planDetails && node.planDetails !== "undefined") {
        return node.planDetails.length > 50? node.planDetails.substring(0, 50) + "..." : node.planDetails
      } else if (node.catalogAdditionalFieldByReportId && node.catalogAdditionalFieldByReportId.weatherNotes && node.catalogAdditionalFieldByReportId.weatherNotes !== "undefined") {
        return node.catalogAdditionalFieldByReportId.weatherNotes.length > 50? node.catalogAdditionalFieldByReportId.weatherNotes.substring(0, 50) + "..." : node.catalogAdditionalFieldByReportId.weatherNotes
      } else {
        return "No short description provided"
      }
    },
    calendarReportsSelected(date, reportTypeId) {
      const minimumDate = this.$moment?.utc(date)?.startOf("day")
      const maximumDate = this.$moment?.utc(date)?.endOf("day")

      const reportsPayload = {
        campaignId: this.getCampaignId,
        minimumDate,
        maximumDate,
        reportTypeId,
      }
      store.dispatch("reports/getCalendarReportsByDateAndReportType", reportsPayload).then(response => {
        if (response.status === 200) {
          this.availableReports = response?.data?.data?.allCatalogReports?.edges?.map(edge => edge?.node)?.map(node => {
            return {
              key: node.reportId,
              reportId: node.reportId,
              submitter: node.personByPersonId.nameFirst + " " + node.personByPersonId.nameLast,
              shortDescription: this.getShortDescription(node),
              reportDate: this.$moment.utc(node.reportDate).format("YYYY-MM-DD HH:mm:ss"),
              updated: this.$moment.utc(node.reportUpdated.datetime).format("YYYY-MM-DD HH:mm:ss"),
              action: [
                {
                  key: "editReport",
                  label: "View/Edit Report",
                },
              ],
            }
          })
          this.$bvModal.show("availableReports")
        }
      })

    },
    calendarPlotsSelected(date) {
      const minimumDate = this.$moment?.utc(date)?.startOf("day")
      const maximumDate = this.$moment?.utc(date)?.endOf("day")
      
      const plotsPayload = {
        siteCode: this.getCampaignSiteCode,
        startDate: this.$moment(minimumDate).format("YYYYMMDD"),
        endDate: this.$moment(maximumDate).format("YYYYMMDD"),
      }
      this.$store.dispatch("plots/getCalendarPlotsByDate", plotsPayload)
      this.$bvModal.show("availablePlots")
    },
    calendarContextUpdated(contextDate) {
      const minimumActiveDate = this.$moment?.utc(contextDate)?.startOf("month")?.format("YYYY-MM-DD")
      const maximumActiveDate = this.$moment?.utc(contextDate)?.endOf("month")?.format("YYYY-MM-DD")

      if (minimumActiveDate && maximumActiveDate) {
        let reportEvents = []
        let plotEvents = []
        const reportColors = ["bg-primary", "bg-info", "background-light"]
        const payload = {
          campaignId: this.getCampaignId,
          minimumDate: minimumActiveDate,
          maximumDate: maximumActiveDate,
          siteCode: this.getCampaignSiteCode,
          startDate: minimumActiveDate,
          endDate: maximumActiveDate,
        }
        // get reports and plots together for the current month
        store.dispatch("reports/getCalendarReportsAndPlotsIdsByMonth", payload).then(response => {
          const currentMonthReports = response?.data?.data?.allCatalogReports?.edges?.map(edge => edge?.node)
          const currentMonthPlots = response?.data?.data?.allDqoPlots?.groupedAggregates?.map(edge => {
            return {
              keys: edge?.keys[0],
              counts: edge?.distinctCount?.id,
            }
          })
          plotEvents =  currentMonthPlots.map((plot, index) => {
            return {
              id: plot.keys+":"+index,
              type: "plot",
              title: "Plots Available",
              count: plot.counts,
              color: "bg-danger",
              date: plot.keys,
            }
          })
          // get reports by date and type
          const reducedReports = currentMonthReports.reduce((report, obj) => {
            // reduce reports by main date (without time) and then by report type
            const formattedDate = this.$moment(obj.reportDate).format("YYYY-MM-DD")
            const type = obj.reportTypeId
            if (!report[formattedDate]) report[formattedDate] = {}
            if (!report[formattedDate][type]) report[formattedDate][type] = []
            report[formattedDate][type].push(obj)
            return report
          }, {})
          const consolidatedReports = []
          // create list of events bases on report type for a given date
          for (const date in reducedReports) {
            let colorIndex=0 // assign different color to each report type
            for (const reportType in reducedReports[date]) {
              consolidatedReports.push({
                id: reportType,
                type: reportType,
                title: reducedReports[date][reportType][0].catalogReportTypeByReportTypeId.reportTypeDesc,
                count: reducedReports[date][reportType].length,
                color: reportColors[colorIndex++],
                date,
              })
            }
          }
          reportEvents = consolidatedReports

          const combinedEvents = reportEvents.concat(plotEvents) // combine reports and plots events to be displayed in calendar
          this.events = combinedEvents
          // EventBus.$emit("updateCalendarEvents", combinedEvents)
        })
        if (this.getCampaignSiteCode === "kcg") {
          this.getCalendarStationReports(contextDate)
        }
      }
    },
    editReport(reportId) {
      // Edit/View reports in new tab...
      const reportsRoute = this.$router.resolve({path: `/${this.getCampaignAcronym}/report/${reportId}`})
      window.open(reportsRoute.href, "_blank")
    },
    getCalendarStationReports(contextDate) {
      const locationUrl = window.location.origin
      const envUrl = locationUrl.includes("localhost") ? "https://adc.arm.gov" : locationUrl
      const reportDate = this.$moment?.utc(contextDate)?.format("YYYYMM")
      return axios.get(`${envUrl}/images/afcd/KCG/?C=N;O=D`)  
        .then(results => {
          // Convert the HTML string into a document object
          const parser = new DOMParser()
          const doc = parser.parseFromString(results.data, "text/html")
          // parse the files which are in HTML
          // Get the image file
          let reportAnchors = ""
          try {
            reportAnchors = doc.querySelectorAll(`[href*='${reportDate}']`)
            const reportUrls = []
            this.stationReports = []
            for (const i of reportAnchors) {
              const reportInfo= i?.attributes?.href?.nodeValue?.split("_")
              // reportInfo[0] has the report date. Use that to put calendar event per date
              const formattedDate = this.$moment(reportInfo[0]).format("YYYY-MM-DD")
              reportUrls.push(`https://adc.arm.gov/images/afcd/KCG/${i?.attributes?.href?.nodeValue}`)
              this.stationReports.push({
                id: i?.attributes?.href?.nodeValue,
                type: "station_report",
                title: `KCGBAPS Daily Report`,
                color: "bg-warning",
                date: formattedDate,
                url: `https://adc.arm.gov/images/afcd/KCG/${i?.attributes?.href?.nodeValue}`,
              })
            }
          } catch (error) {
            console.log("Error while parsing the HTML", error)
          }
        }).catch(err => {
          console.log(`Error while fetching reports: ${err}`)
        })
    },
  },
}
</script>

<template>
  <div class="m-3">
    <b-row>
      <b-col>
        <h1 class="font-weight-bold">Campaign Calendar</h1>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <Calendar v-if="recentDate" :first-day="1" :all-events="events.concat(stationReports)" :recent-date="recentDate"/>
      </b-col>
    </b-row>
    <!-- Reports Modal -->
    <b-modal id="availableReports" size="xl" centered>
      <template #modal-title>
        <div><b>{{ modalDetails.title }} Reports for </b>{{ modalDetails.date }}</div>
      </template>
      <div class="d-block">
        <ResultsContainer
          :rows="availableReports"
          :fields="availableReportsFields"
          hide-totals
          @editReport="editReport"
        />
      </div>
      <div slot="modal-footer">
        <b-button variant="danger" @click="$bvModal.hide('availableReports')">Close</b-button>
      </div>
    </b-modal>
    <!-- Plots Modal -->
    <b-modal id="availablePlots" size="xl" centered>
      <template #modal-title>
        <div><b>{{ getCampaignAcronym.toUpperCase() }} Data Plots for </b>{{ modalDetails.date }}</div>
      </template>
      <b-container id="dataPlots">
        <b-row 
          v-if="loadingPlots"
          class="text-center mt-4 mb-4"
        >
          <b-col cols="12"><Spinner text="Loading plots..." /></b-col>
        </b-row>
        <b-row v-else>
          <PlotFilter :plot-date="modalDetails.date"/>
          <div v-if="Object.keys(filterPlots).length > 0">
            <div 
              v-for="plotType in Object.keys(filterPlots)" 
              :key="plotType"
            >
              <PlotResults
                :type="plotType"
                :plots="filterPlots[plotType]"
              />
            </div>
          </div>
        </b-row>
      </b-container>
      <div slot="modal-footer">
        <b-button variant="danger" @click="$bvModal.hide('availablePlots')">Close</b-button>
      </div>
    </b-modal>
    <!-- Station Report Modal -->
    <b-modal id="availableStationReport" size="xl" centered>
      <template #modal-title>
        <div><b>{{ modalDetails.title }} for </b>{{ modalDetails.date }}</div>
      </template>
      <div class="d-block">
        <embed class="modalpdf" :src="modalDetails.url" frameborder="0" width="100%"/>
      </div>
      <div slot="modal-footer">
        <b-button variant="danger" @click="$bvModal.hide('availableStationReport')">Close</b-button>
      </div>
    </b-modal>
  </div>
</template>

<style>
.modalpdf {
  height: 800px !important;
}
</style>
