<script>
import { mapGetters } from "vuex"
import store from "@/store/index.js"
import ResultsContainer from "@/components/ResultsContainer"

export default {
  components: {
    ResultsContainer,
  },
  data: () => ({
    datePickerData: {},
    availableReports: [],
    availableReportsFields: [
      { key: "reportType", label: "View Files" },
      { key: "lastReport", label: "Last Report" },
      { key: "action", label: "Action" },
    ],
  }),
  computed: {
    ...mapGetters("global", ["getCampaignId", "getCampaignAcronym"]),
  },
  mounted() {
    this.updateAvailableReports()
  },
  methods: {
    updateAvailableReports() {
      while (this.availableReports.length > 0) {
        this.availableReports.pop()
      }

      store.dispatch("reports/getReportTypes", this.getCampaignId).then(response => {
        response?.data?.data?.allCatalogCampaignReportTypes?.edges?.forEach(edge => {
          const report = edge?.node?.catalogReportTypeByReportTypeId
          const lastReportDate = report?.catalogReportsByReportTypeId?.nodes?.[0]?.reportDate
          const lastReportDateFormatted = lastReportDate ?
            this.$moment.utc(lastReportDate).format("YYYY-MM-DD HH:mm:ss") :
            "N/A"
          this.availableReports.push({
            id: report.reportTypeId,
            key: report.reportTypeName,
            reportType: report.reportTypeDesc,
            lastReport: lastReportDateFormatted,
            action: [
              {
                key: "newReport",
                label: "New Report",
              },
              {
                key: "viewReports",
                label: "View Reports",
              },
            ],
          })
        })
      })
    },
    newReport(type) {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/report/${type}/new`,
      })
    },
    viewReports(type) {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/report/${type}/list`,
      })
    },
  },
}
</script>

<template>
  <b-container class="pt-3">
    <b-row>
      <b-col>
        <h2 class="font-weight-bold">Field Reports</h2>
        <h1 class="font-weight-bold">Available Reports</h1>
      </b-col>
    </b-row>
    <ResultsContainer
      :rows="availableReports"
      :fields="availableReportsFields"
      hide-totals
      @newReport="newReport"
      @viewReports="viewReports"
    />
  </b-container>
</template>

<style scoped></style>
