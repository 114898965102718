<script>
export default {
  props: {
    fields: { default: () => [], type: Array },
    rows: { default: () => [], type: Array },
  },
  data: () => ({
    sortBy: null,
    sortDesc: true,
  }),
  computed: {
    totalRows() {
      return this.rows.length
    },
    sort() {
      return {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      }
    },
  },
  watch: {
    sort(value) {
      this.$emit("sortChanged", value)
    },
  },
  methods: {
    onRowClick(data) {
      if (data) {
        this.$emit("rowClick", data)
      }
    },
  },
}
</script>

<template>
  <b-col cols="12" class="resultsContainer">
    <div>
      <b-table
        :fields="fields"
        :items="rows"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        small
        bordered
        hover
        head-variant="dark"
        tbody-td-class="resultsCell"
        class="resultsTable"
        @row-clicked="onRowClick"
      >
        <template #cell(action)="data">
          <b-btn
            v-for="(row, index) in data.item.action"
            :key="index"
            size="sm"
            class="background-blue mr-2"
            squared
            @click="$emit(row.key, data.item.key)"
          >
            {{ row.label }}
          </b-btn>
        </template>
        <template #cell(shortDescription)="data">
          <div v-html="data.item.shortDescription"/>
        </template>
      </b-table>
    </div>
  </b-col>
</template>

<style>
.resultsContainer {
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0 !important;
}
.resultsContainer .containerContent label {
  margin-bottom: 0px;
}

/************************
**        Table        **
************************/
.resultsContainer table {
  border-collapse: separate;
  border-spacing: 1px 3px;
  border: none;
}
/************************
**     Table Header    **
************************/
.resultsContainer table thead tr th {
  padding-left: 15px;
  background-color: #194173 !important;
  color: #fff;
}
.resultsContainer table thead tr th:first-child {
  border-top-left-radius: 15px;
}
.resultsContainer table thead tr th:last-child {
  border-top-right-radius: 15px;
}
/* Adjust sort icons due to rounded corners */
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.75rem / 2) center !important;
}
/************************
**      Table Rows     **
************************/
.resultsContainer table tbody tr td {
  background-color: #fff;
  padding-left: 15px;
}
.resultsContainer table tbody tr:hover td {
  background-color: #c5dfed !important;
  cursor: pointer;
}
.resultsContainer .sectionHeaderButtons button {
  margin-top: -8px;
  margin-left: 4px;
}
.resultsContainer .sectionHeaderCollapseButton button,
.resultsContainer .sectionHeaderCollapseButton button:link,
.resultsContainer .sectionHeaderCollapseButton button:visited {
  color: #fff;
  text-decoration: none;
}
.resultsContainer .sectionHeaderCollapseButton button:active,
.resultsContainer .sectionHeaderCollapseButton button:hover {
  color: #fff;
  text-decoration: underline;
}
</style>
