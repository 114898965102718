import axios from "axios"
import Config from "/config.js"
import plotFilters from "./plotFilters.json"
const plots = {
  namespaced: true,
  state: {
    plots: [],
    instrumentClasses: [],
    instrumentClassesDescriptions: [],
    calendarInstrumentClasses: [],
    plotOptions: {
      facilityCode: [],
      facilityName: [],
      dataLevelCode: [],
      plotName: [],
      filenameDate: [],
    },
    loadingPlots: false,
    plotSelectedFilters: {},
  },
  mutations: {},
  actions: {
    retrieveInstClasses: ({ state, rootState }, payload) => {
      let instrumentClassCodeFilter = "{isNull: false}"
      for (const item of plotFilters) { 
        if (item.campaign == payload.campaignAcronym) {
          instrumentClassCodeFilter = `{isNull: false, in: ${JSON.stringify(item.instrumentClassFilter)}}`
        }
      }
      axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                comparison: {
                  equalTo: false
                },
                instrumentClassCode: ${instrumentClassCodeFilter}
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              groupedAggregates(groupBy: INSTRUMENT_CLASS_CODE) {
                keys
              }
            }
          }`,
        },
        withCredentials: false,
      }).then(response => {
        if (response.data.data.allDqoPlots.groupedAggregates) {
          state.instrumentClasses = response.data.data.allDqoPlots.groupedAggregates
        }
      }).catch(error => {
        console.error("Error:", error)
        state.instrumentClasses = []
      })
    },
    retrieveInstClassesDescriptions: ({ state, rootState }, payload) => {
      axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allInstrClassDetails(
              filter: {
                instrumentClassCode: {
                  in: ${payload.instrumentClassCode}
                }
              }
            ) {
              nodes {
                instrumentClassCode
                instrumentClassDescription
              }
            }
          }`,
        },
      }).then(response => {
        if (response.data.data.allInstrClassDetails.nodes) {
          state.instrumentClassesDescriptions = response.data.data.allInstrClassDetails.nodes
        }
      }).catch(error => {
        console.error("Error:", error)
        state.instrumentClassesDescriptions = []
      })
    },
    retrievePlotOptions: ({ state, rootState }, payload) => {
      state.plotOptions = {
        facilityCode: [],
        facilityName: [],
        dataLevelCode: [],
        plotName: [],
        filenameDate: [],
      }
      axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            FACILITY_CODE: allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                comparison: {
                  equalTo: false
                },
                instrumentClassCode: {
                  in: "${payload.instrumentClass}"
                },
                facilityCode: {
                  isNull: false
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              groupedAggregates(groupBy: FACILITY_CODE) {
                keys
              }
            }
            FACILITY_NAME: allFacilityInfos(
              filter: {
                siteCode: {
                  equalTo: "${payload.siteCode}"
                }
              },
            ) {
              edges {
                node {
                  facilityCode
                  facilityName
                  siteCode
                }
              }
            }
            DATA_LEVEL_CODE: allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                comparison: {
                  equalTo: false
                },
                instrumentClassCode: {
                  in: "${payload.instrumentClass}"
                },
                dataLevelCode: {
                  isNull: false
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              groupedAggregates(groupBy: DATA_LEVEL_CODE) {
                keys
              }
            }
            PLOT_NAME: allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                comparison: {
                  equalTo: false
                },
                instrumentClassCode: {
                  in: "${payload.instrumentClass}"
                },
                plotName: {
                  isNull: false
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              groupedAggregates(groupBy: PLOT_NAME) {
                keys
              }
            }
            FILENAME_DATE: allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                comparison: {
                  equalTo: false
                },
                instrumentClassCode: {
                  in: "${payload.instrumentClass}"
                },
                filenameDate: {
                  isNull: false
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              groupedAggregates(groupBy: FILENAME_DATE) {
                keys
              }
            }
          }`,
        },
        withCredentials: false,
      }).then(response => {
        if (response.data.data.FACILITY_CODE.groupedAggregates) {
          state.plotOptions.facilityCode = response.data.data.FACILITY_CODE.groupedAggregates
        }
        if (response.data.data.FACILITY_NAME.edges) {
          const facilityObj = {}
          for (const el of response.data.data.FACILITY_NAME.edges) {
            facilityObj[el.node.facilityCode] = el.node.facilityName
          }
          state.plotOptions.facilityName = facilityObj
        }
        if (response.data.data.DATA_LEVEL_CODE.groupedAggregates) {
          state.plotOptions.dataLevelCode = response.data.data.DATA_LEVEL_CODE.groupedAggregates
        }
        if (response.data.data.PLOT_NAME.groupedAggregates) {
          state.plotOptions.plotName = response.data.data.PLOT_NAME.groupedAggregates
        }
        if (response.data.data.FILENAME_DATE.groupedAggregates) {
          state.plotOptions.filenameDate = response.data.data.FILENAME_DATE.groupedAggregates
        }
      }).catch(error => {
        console.error("Error:", error)
        state.plotOptions = {
          facilityCode: [],
          dataLevelCode: [],
          plotName: [],
          filenameDate: [],
        }
      })
    },
    retrievePlots: ({ state, rootState }, payload) => {
      state.loadingPlots = true
      state.plots = []
      state.plotSelectedFilters = payload
      axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                instrumentClassCode: {
                  in: "${payload.instrumentClass}"
                },
                dataLevelCode: {
                  in: ${payload.dataLevelCode}
                },
                comparison: {
                  equalTo: false
                },
                facilityCode: {
                  in: ${payload.facility}
                },
                plotName: {
                  in: ${payload.plotName}
                },
                filenameDate: {
                  greaterThanOrEqualTo: "${payload.startDate}",
                  lessThanOrEqualTo: "${payload.endDate}"
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              edges {
                node {
                  id
                  fullPathName
                  plotName
                  siteCode
                  filenameDate
                  fileSize
                  facilityCode
                  instrumentClassCode
                  comparison
                  urlEnd
                  extension
                }
              }
            }
          }`,
        },
        withCredentials: false,
      }).then(response => {
        state.loadingPlots = false
        const recs = response.data.data.allDqoPlots.edges
        state.plots = recs
      }).catch(error => {
        console.error("Error:", error)
        state.loadingPlots = false
        state.plots = []
        state.plotSelectedFilters = {}
      })
    },
    getCalendarPlotsByDate: ({ state, rootState }, payload) => {
      state.loadingPlots = true
      state.plots = []
      state.plotSelectedFilters = payload
      axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                filenameDate: {
                  greaterThanOrEqualTo: "${payload.startDate}",
                  lessThanOrEqualTo: "${payload.endDate}"
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              edges {
                node {
                  id
                  fullPathName
                  plotName
                  siteCode
                  filenameDate
                  fileSize
                  facilityCode
                  instrumentClassCode
                  comparison
                  urlEnd
                  extension
                }
              }
            }
          }`,
        },
      }).then(response => {
        state.loadingPlots = false
        const recs = response.data.data.allDqoPlots.edges
        state.plots = recs
      }).catch(error => {
        console.error("Error:", error)
        state.loadingPlots = false
        state.plots = []
        state.plotSelectedFilters = {}
      })
    },
    retrieveInstClassesByDate: ({ state, rootState }, payload) => {
      let instrumentClassCodeFilter = "{isNull: false}"
      for (const item of plotFilters) { 
        if (item.campaign == payload.campaignAcronym) {
          instrumentClassCodeFilter = `{isNull: false, in: ${JSON.stringify(item.instrumentClassFilter)}}`
        }
      }
      axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                comparison: {
                  equalTo: false
                },
                instrumentClassCode: ${instrumentClassCodeFilter},
                filenameDate: {
                  greaterThanOrEqualTo: "${payload.startDate}",
                  lessThanOrEqualTo: "${payload.endDate}"
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              groupedAggregates(groupBy: INSTRUMENT_CLASS_CODE) {
                keys
              }
            }
          }`,
        },
      }).then(response => {
        if (response.data.data.allDqoPlots.groupedAggregates) {
          state.calendarInstrumentClasses = response.data.data.allDqoPlots.groupedAggregates
        }
      }).catch(error => {
        console.error("Error:", error)
        state.calendarInstrumentClasses = []
      })
    },
    clearPlots({state}) {
      state.plots = []
      state.plotSelectedFilters = {}
    },
  },
}

export default plots
