<script>
import { mapState } from "vuex"
import Spinner from "@/components/Spinner"
import PlotFilter from "@/components/PlotFilter"
import PlotResults from "@/components/PlotResults"

export default {
  components: {
    Spinner,
    PlotFilter,
    PlotResults,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState("plots", ["plots", "loadingPlots", "plotSelectedFilters", "instrumentClasses"]),
    filterPlots() {
      const groupPlots =
        this.plots && this.plots.length > 0
          ? this.groupPlotsByFacility(this.plots)
          : []
      return groupPlots
    },
  },
  created() {
    this.$store.dispatch("plots/clearPlots")
  },
  methods: {
    groupPlotsByFacility (data) {
      const grouped = data.reduce((result, item) => {
        const plotName = item.node.plotName
        const facilityCode = item.node.facilityCode
        // Initialize plotName group if it doesn't exist
        if (!result[plotName]) {
          result[plotName] = {}
        }
        // Initialize facilityCode group within plotName if it doesn't exist
        if (!result[plotName][facilityCode]) {
          result[plotName][facilityCode] = []
        }
        // Add the current node to the appropriate group
        result[plotName][facilityCode].push(item)
        return result
      }, {})
      return grouped
    },
  },
}
</script>

<template>
  <b-container id="dataPlots">
    <PlotFilter />
    <b-row
      v-if="loadingPlots"
      class="text-center mt-4 mb-4"
    >
      <b-col cols="12">
        <Spinner text="Loading plots..." />
      </b-col>
    </b-row>
    <!-- only show this section if there are any filters or plots available -->
    <b-row v-else-if="instrumentClasses.length > 0">
      <b-col
        v-if="Object.keys(plotSelectedFilters).length > 0"
        class="mb-4"
      >
        <div v-if="Object.keys(filterPlots).length > 0">
          <div
            v-for="type in Object.keys(filterPlots)"
            :key="type"
          >
            <h4 class="data-plot-type-label">{{ type }}</h4>
            <div
              v-for="facilityType in Object.keys(filterPlots[type])"
              :key="facilityType"
            >
              <PlotResults
                :type="facilityType"
                :plots="filterPlots[type][facilityType]"
              />
            </div>
          </div>
        </div>
        <div v-else>
          No plots found.
        </div>
      </b-col>
      <b-col v-else>
        <b-card class="noResults background-light border-0 text-center mt-2">
          <b-card-title>
            No Filters Applied
          </b-card-title>
          <b-card-text>
            <span class="selectText">
              Please select a instrument class and click 'Get Plots' to view available data plots.
            </span>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
#dataPlots {
  display: flex;
  flex-flow: column;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  background-color: #efefef;
  overflow: scroll;
  padding: 30px;
}

#dataPlots .noResults .card-title {
  font-family: "League Gothic";
  font-size: 2em;
}

#dataPlots .selectText {
  font-size: 1.2em;
}
.data-plot-type-label {
  font-weight: 700;
  font-size: 20px;
  color: #194173;
  padding-top: 10px;
}
</style>
