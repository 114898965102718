<script>
import moment from "moment"
import { EventBus } from "@/event-bus.js"
import EventCard from "./CalendarEventCard"
export default {
  components: {
    EventCard,
  },
  props:{
    day: {
      type: Object,
    },
    firstDay: {
      type: String,
    },
  },
  data () {
    return {
      isDaySelected: false,
    }
  },
  created() {
    const self = this
    EventBus.$on("DAY_SELECTED", (payload) => {
      if (payload.dayDate != self.day.date) {
        self.isDaySelected = false
      }
    })
    EventBus.$on("CHANGE_MONTH", () => {
      self.isDaySelected = false
    })
  },
  methods : {
    showDayOptions() {
      const self = this
      const startOfToday = moment().startOf("day")
      if (this.day.date.isAfter(startOfToday) || this.day.date.isSame(startOfToday)) {
        this.isDaySelected = true
        EventBus.$emit("DAY_SELECTED", {dayDate:self.day.date})
      }
    },
    // showAddEventForm() {
    //   // TODO: Implement add event form -- this can be a fucntionality to add new reports from calendar directly
    //   alert("Can you help implementing this?")
    // },
  },
}
</script>
<template>
  <div
    class="day-cell"
    :class="{'today' : day.isToday, 'current-month' : day.isCurrentMonth, 'weekend': day.isWeekEnd, 'selected-day':isDaySelected}"
    @click="showDayOptions"
  >
    <div class="row">
      <div class="col-sm-6">
        <!-- <div v-show="isDaySelected">
                    <span class="label label-success" @click="showAddEventForm"> {{ "Add Event" }}</span>
                </div> -->
      </div>
      <div class="col-sm-6">
        <p class="day-number">{{ day.date.format('D') }}</p>
      </div>
    </div>
    <div class="event-box">
      <EventCard
        v-for="event in day.events"
        :key="event.id"
        :event="event"
        :day-date="day.date"
        :is-day-selected="isDaySelected"
      />
    </div>
  </div>
</template>
<style>
.day-cell {
    flex: 1;
    min-height: 112px;
    padding: 4px;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    background: rgba(147, 147, 147, 0.1);
}

.day-number {
    text-align: right;
    color: rgba(0, 0, 0, .25);
    font-size: 1em;
    padding: 5px;
}

.current-month {
    background: #fff;
}

.current-month p {
    color: rgba(0, 0, 0, .5);
    font-size: 1.5em;
}

.selected-day p {
    font-size: 2.4em;
    font-weight: bolder;
}

.weekend p {
    color: rgba(210, 2, 2, 0.6);
}

.today {
    background-color: #e8fde7;
}

.today p {
    font-size: 2em;
    font-weight: bolder;
    color: #367016;
}
</style>
