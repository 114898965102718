<script>
import { mapGetters } from "vuex"

export default {
  props: {
    mini: { default: false, type: Boolean },
  },
  data: () => ({
    campaignHasCharts: false,
    refreshKey: 0,
    sections: [
      {
        key: "dashboard",
        label: "Dashboard",
        path: "/dashboard",
      },
      {
        key: "overview",
        label: "Campaign Overview",
        path: "/overview",
      },
      {
        key: "calendar",
        label: "Campaign Calendar",
        path: "/calendar",
      },
      {
        key: "reports",
        label: "Reports",
        children: [
          [
            {
              label: "Available Reports",
              path: "/reports",
            },
          ],
          [],
          [],
        ],
      },
      {
        key: "plots",
        label: "Plots",
        path: "/plots",
      },
      {
        key: "maps",
        label: "Maps & Locations",
        path: "/maps",
      },
      {
        key: "maintenance",
        label: "Maintenance Reports",
        path: "/maintenance",
      },
      {
        key: "links",
        label: "Tools & Links",
        mini: true,
        children: [],
      },
      {
        key: "contacts",
        label: "Contacts",
        path: "/contacts",
      },
      {
        key: "askus",
        label: "Ask Us",
        mini: true,
        modal: "ask-us",
      },
    ],
    defaultToolLinks:[
      {
        label: "Maintenance Tools",
        url: "https://adc.arm.gov/ARMMaintenanceTools",
      },
      {
        label: "DQ Plotbrowser",
        url: "https://dq-plotbrowser-ui.svcs.arm.gov/",
      },
      {
        label: "DQ-Zoom Plotter",
        url: "https://dq.arm.gov/dq-zoom/",
      },
    ],
  }),
  computed: {
    ...mapGetters("global", ["getCampaignId", "getCampaignAcronym", "getCampaignCode", "getCampaignSiteCode"]),
    filteredSections() {
      if (this.mini) {
        return this.sections.filter(section => section.mini)
      } else {
        // Removing maps link for Tracer campaign as UCAR map is no longer available
        // Requested by Giri
        if (this.sections && this.getCampaignAcronym && this.getCampaignAcronym === "tracer") {
          return this.sections.filter(section => {
            return section.key !== "maps" 
          })
        } else {
          return this.sections
        }
      }
    },
  },
  watch: {
    getCampaignId(val) {
      if (val) {
        this.$store.dispatch("global/getCharts", val).then(response => {
          if (response?.data?.data?.allCatalogCharts?.edges?.length > 0) {
            this.campaignHasCharts = true
          } else {
            this.campaignHasCharts = false
          }
        })

        this.$store.dispatch("reports/getReportTypes", val).then(response => {
          const reportsIndex = this.sections?.findIndex(element => element?.key === "reports")

          while (this.sections[reportsIndex].children[1].length > 0) {
            this.sections[reportsIndex].children[1].pop()
          }
          
          while (this.sections[reportsIndex].children[2].length > 0) {
            this.sections[reportsIndex].children[2].pop()
          }

          response?.data?.data?.allCatalogCampaignReportTypes?.edges?.forEach(edge => {
            this.sections[reportsIndex].children[1].push({
              label: "New " + edge?.node?.catalogReportTypeByReportTypeId?.reportTypeDesc,
              path: "/report/" + edge?.node?.catalogReportTypeByReportTypeId?.reportTypeName + "/new",
            })
            this.sections[reportsIndex].children[2].push({
              label: "View " + edge?.node?.catalogReportTypeByReportTypeId?.reportTypeDesc,
              path: "/report/" + edge?.node?.catalogReportTypeByReportTypeId?.reportTypeName + "/list",
            })
          })
        })
      }
    },
    getCampaignCode(val) {
      const linksIndex = this.sections.findIndex(section => section.key === "links")
      if (linksIndex > -1) {
        const defaultLinks = []
        if (this.getCampaignAcronym === "bnf") {
          defaultLinks.push({
            label: "Data Discovery", 
            url: `https://adc.arm.gov/discovery/#/results/site_code::${this.getCampaignAcronym}` ,
          })
        } else {
          defaultLinks.push({
            label: "Data Discovery", 
            url: val ? `https://adc.arm.gov/discovery/#/results/iopShortName::${val}` : "https://adc.arm.gov/discovery/",
          })
        }
        defaultLinks.push({
          label: "Data Availability PDFs",
          url: this.getCampaignSiteCode ? `https://github.com/ARM-Development/AFC_Summary/blob/main/images/${this.getCampaignSiteCode}` : "https://github.com/ARM-Development/AFC_Summary/blob/main/images/",
        })
        if (this.getCampaignAcronym === "epcape") {
          defaultLinks.push({
            label: "NASA Langley Imagery",
            // url: "https://satcorps.larc.nasa.gov/cgi-bin/site/showdoc?docid=4&cmd=field-experiment-homepage&exp=ARM-EPCAPE",
            url: "https://satcorps.larc.nasa.gov/cgi-bin/site/showdoc?docid=22&lkdomain=Y&domain=ARM-AMF-EPCAPE-EXP",
          })
        }
        if (this.getCampaignAcronym === "courage") {
          defaultLinks.push({
            label: "NASA Langley Imagery",
            url: "https://satcorps.larc.nasa.gov/cgi-bin/site/showdoc?docid=22&lkdomain=Y&domain=ARM-AMF-COURAGE",
          })
        }
        // Adding a conditional to render additional dropdown options for CAPE-k
        // This logic can be reworked in the future to make it data-driven
        if (this.getCampaignAcronym === "cape-k") {
          defaultLinks.push({
            label: "Weather Forecast",
            url: "https://www.bom.gov.au/tas/observations/tasall.shtml",
          })
          defaultLinks.push({
            label: "NASA Langley Imagery",
            // url: "https://satcorps.larc.nasa.gov/cgi-bin/site/showdoc?docid=4&cmd=field-experiment-homepage&exp=ARM-CAPEK",
            url: "https://satcorps.larc.nasa.gov/cgi-bin/site/showdoc?docid=22&lkdomain=Y&domain=ARM-AMF-CAPEK",
          })
        }
        if (this.getCampaignAcronym === "bnf") {
          defaultLinks.push({
            label: "Data Dashboard",
            // url: "https://satcorps.larc.nasa.gov/cgi-bin/site/showdoc?docid=4&cmd=field-experiment-homepage&exp=ARM-EPCAPE",
            url: "https://idata.svcs.arm.gov/",
          })
        }
        const linksNavigationItem = {
          key: "links",
          label: "Tools & Links",
          children: [this.defaultToolLinks.concat(defaultLinks)],
        }
        this.sections[linksIndex] = linksNavigationItem
        this.refreshKey++
      }
    },
    campaignHasCharts(val) {
      if (val) {
        const plotsIndex = this.sections.findIndex(section => section.key === "plots")
        if (plotsIndex > -1) {
          const plotsNavigationItem = {
            key: "plots",
            label: "Plots",
            children: [
              [
                {
                  label: "Data Plots",
                  path: "/plots",
                },
                {
                  label: "Charts",
                  path: "/charts",
                },
              ],
            ],
          }
          this.sections[plotsIndex] = plotsNavigationItem
          this.refreshKey++
        }
      } else {
        const plotsIndex = this.sections.findIndex(section => section.key === "plots")
        if (plotsIndex > -1) {
          const plotsNavigationItem = {
            key: "plots",
            label: "Plots",
            path: "/plots",
          }
          this.sections[plotsIndex] = plotsNavigationItem
          this.refreshKey++
        }
      }
    },
  },
  methods: {
    navItemClick(item) {
      if (item.path) {
        this.$router.push({
          path: `/${this.getCampaignAcronym}${item.path}`,
        })
      } else if (item.url) {
        const win = window.open(item.url, "_blank")
        win.focus()
      } else if (item.modal) {
        this.$bvModal.show(item.modal)
      }
    },
    checkSelected(item) {
      const routeSplit = this.$route.path.split("/")
      const route = routeSplit[routeSplit.length - 1]
      if (item === route) {
        return "selected"
      } else {
        return null
      }
    },
  },
}
</script>

<template>
  <b-nav
    :key="refreshKey"
    class="background-blue"
  >
    <div
      v-for="(navItem, navItemIndex) in filteredSections"
      :key="navItemIndex"
    >
      <b-nav-item-dropdown
        v-if="navItem.children"
        :text="navItem.label"
        menu-class="foreground-blue"
      >
        <div
          v-for="(navGroup, navGroupIndex) in navItem.children"
          :key="navGroupIndex"
        >
          <b-dropdown-item
            v-for="(navLink, navLinkIndex) in navGroup"
            :key="navLinkIndex"
            @click="navItemClick(navLink)"
          >
            {{ navLink.label }}
          </b-dropdown-item>
          <b-dropdown-divider v-if="navGroupIndex < navItem.children.length - 1" />
        </div>
      </b-nav-item-dropdown>
      <b-nav-item
        v-else
        v-b-tooltip="navItem.tooltip"
        :class="checkSelected(navItem.key)"
        @click="navItemClick(navItem)"
      >
        {{ navItem.label }}
      </b-nav-item>
    </div>
  </b-nav>
</template>

<style scoped>
a, a:hover {
  color: white !important;
}

::v-deep .dropdown-toggle {
  color: white !important;
}

::v-deep .dropdown-menu {
  border-radius: 0;
  margin-top: 0;
  border-top: none;
}

::v-deep .dropdown-item {
  color: #194173 !important;
}

.selected {
  font-weight: bold;
  text-decoration: underline;
  color: white !important;
}
</style>
