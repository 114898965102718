<script>
import moment from "moment"
import CalendarHeader from "./CalendarHeader"
import Week from "./CalendarWeek"
import { EventBus } from "@/event-bus.js"

export default {
  components: {
    CalendarHeader,
    Week,
  },
  filters: {
    weekDayName (weekday, firstDay, locale) {
      firstDay = parseInt(firstDay)
      const localMoment = moment().locale(locale)
      return localMoment.localeData().weekdaysShort()[(weekday + firstDay) % 7]
    },
  },
  props: {
    allEvents: {
      type: Array,
      default () {
        return []
      },
    },
    // eslint-disable-next-line
		firstDay: {
      type: Number,
      default () {
        return 0
      },
    },
    recentDate: {
      type: String,
      default () {
        return moment()
      },
    },
  },
  data () {
    return {
      loading: true,
      error: null,
      currentMonth: moment(this.recentDate).startOf("month"),
    }
  },
  computed: {
    Weeks () {
      const monthMomentObject = this.getMonthViewStartDate(this.currentMonth, this.firstDay)
      let week = []
      const weeks = []
      const daysInCurrentMonth = this.currentMonth.daysInMonth()
      for ( let weekIndex=0; weekIndex < 5; weekIndex++) {
        week = []
        for (let dayIndex=0; dayIndex < 7; dayIndex++) {
          week.push(this.getDayObject(monthMomentObject, dayIndex))
          monthMomentObject.add(1, "day")
        }
        weeks.push(week)
      }
      const diff = daysInCurrentMonth-weeks[4][6].date.format("D")
      if (diff > 0 && diff < 3) {
        week = []
        for (let dayIndex=0; dayIndex < 7; dayIndex++) {
          week.push(this.getDayObject(monthMomentObject, dayIndex))
          monthMomentObject.add(1, "day")
        }
        weeks.push(week)
      }
      return weeks
    },
    appLocale () {
      return "en"
    },
    events () {
      return this.allEvents
    },
  },
  created () {
    const self = this
    EventBus.$on("CHANGE_MONTH", (payload) => {
      self.currentMonth = payload
    })
  },
  mounted () {
    this.loading = false
  },
  methods: {
    getEvents (date) {
      return this.events.filter(event => {
        return date.isSame(event.date, "day")?event:null
      })
    },
    getMonthViewStartDate (date, firstDay) {
      firstDay = parseInt(firstDay)
      const start = moment(date).locale(this.appLocale)
      const startOfMonth = moment(start.startOf("month"))

      start.subtract(startOfMonth.day(), "days")

      if (startOfMonth.day() < firstDay) {
        start.subtract(7, "days")
      }

      start.add(firstDay, "days")

      return start
    },

    getDayObject(monthMomentObject, dayIndex) {
      return {
        isToday: monthMomentObject.isSame(moment(), "day"),
        isCurrentMonth: monthMomentObject.isSame(this.currentMonth, "month"),
        weekDay: dayIndex,
        isWeekEnd: (dayIndex == 5 || dayIndex == 6),
        date: moment(monthMomentObject),
        events: this.getEvents(monthMomentObject),
      }
    },
  },
}

</script>
<template>
  <div>
    <div v-if="loading">{{ "Loading" }}...</div>
    <div v-if="error" class="error"/>
    <div class="panel panel-default">
      <div class="panel-body">
        <b-row>
          <b-col>
            <CalendarHeader 
              :current-month="currentMonth"
              :first-day="firstDay"
            />

            <div class="full-calendar-body">
              <div class="weeks">
                <strong v-for="dayIndex in 7" :key="dayIndex" class="week">{{ (dayIndex - 1) | weekDayName(firstDay, appLocale) }}</strong>
              </div>

              <div ref="dates" class="dates">
                <Week 
                  v-for="(week, index) in Weeks"
                  :key="index"
                  :first-day="firstDay"
                  :week="week"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<style>
    ul, p {
        margin: 0;
        padding: 0;
    }

    .full-calendar-body {
        margin-top: 20px;
    }
    .weeks {
        display: flex;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
    }
    .week {
        flex: 1;
        padding: 5px;
        text-align: center;
        border-right: 1px solid #e0e0e0;
    }

    .dates {
        position: relative;
    }
</style>
