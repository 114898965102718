<script>
import { mapGetters } from "vuex"
import Spinner from "@/components/Spinner"
import View from "ol/View"
import Map from "ol/Map"
import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import KML from "ol/format/KML"
// we’ll need these additional imports
import ImageLayer from "ol/layer/Image"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import Static from "ol/source/ImageStatic"
import GeoJSON from "ol/format/GeoJSON"
import {Circle as CircleStyle, Stroke, Style, Icon} from "ol/style"
import Overlay from "ol/Overlay"
import { fromArrayBuffer } from "geotiff"
import TileWMS from "ol/source/TileWMS"
import ImageWMS from "ol/source/ImageWMS"
import WMSCapabilities from "ol/format/WMSCapabilities"

// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import "ol/ol.css"
// import "ol-layerswitcher/dist/ol-layerswitcher.css"
import LayerSwitcher from "ol-ext/control/LayerSwitcher"
import "ol-ext/control/LayerSwitcher.css"
import LayerGroup from "ol/layer/Group"
import Button from "ol-ext/control/Button"
import saveAs from "file-saver"

import {transform} from "ol/proj"
import axios from "axios"
import mapList from "./Maps.json"
// import Projection from "ol/proj/Projection"
import markerJSON from "../../public/map/watershed_sfa/sites.json"
export default {
  name: "Maps",
  components: {
    Spinner,
  },
  props: {},
  data: () => ({
    mapImageries:[],
    animateMap:[],
    mapKmls:[],
    mapWatershed:[],
    fcImageList: [],
    mapCenter:[],
    mapZoom:8,
    isIFrame:false,
    iFrameSrc:"",
    mapLoading:true,
    csuCounter:0,
  }),
  computed: {
    ...mapGetters("global", ["getCampaignAcronym"]),
  },
  async created() {
    this.mapLoading = true
    //get current date
    // console.log(this.$route.params.campaign)
    // if a campaign exists then fetch respective imageList
    if (this.getCampaignAcronym) {
      for (const item of mapList) { 
        if (item.campaign === this.getCampaignAcronym) {
          if (item.mapMode === "map") {
            this.mapCenter = item.mapCenter
            this.mapZoom = item.mapZoom
            if (item.imageList) {
              this.fcImageList = item.imageList
            }
            if (item.kmlList) {
              this.mapKmls = item.kmlList
            }
            if (item.WatershedList) {
              this.mapWatershed = item.WatershedList
            }
            const todaysDate = this.getCurrentDate()
            await this.createMapImageries(todaysDate, this.fcImageList)
            await this.initializeMap()
            this.isIFrame = false
          } else {
            this.isIFrame = true
            this.iFrameSrc = item.mapSource
            this.mapLoading = false
          }
        }
      }
    }
  },
  methods:{
    getCurrentDate(offsetDay) {
      const currentDate = new Date()
      const yesterday =  new Date(currentDate)
      let orgDate = ""
      if (offsetDay) {
        yesterday.setDate(yesterday.getDate() - parseInt(offsetDay))
        orgDate = yesterday.getDate()
      }  else {
        orgDate = currentDate.getDate()
      }
      //eslint-disable-next-line
      const day = ("0" + orgDate).slice(-2)
      //eslint-disable-next-line
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2)
      const year = currentDate.getFullYear()
      //eslint-disable-next-line
      const todaysDate =  year +""+ month +""+ day
      return todaysDate
    }, 
    formattedDate(timestamp) {
      const datePattern = /(\d{4})(\d{2})(\d{2})/
      //eslint-disable-next-line
      const convertedDate = timestamp.replace(datePattern,"$1-$2-$3")+"T00:00:00"
      const formattedDate = new Date(convertedDate).toDateString()
      return formattedDate
    },
    formattedTime(timestamp) {
      const timePattern = /(\d{2})(\d{2})(\d{2})/
      const formattedTime = timestamp.replace(timePattern,"$1:$2:$3")
      return formattedTime
    },
    async createMapImageries(todaysDate, imageList) {
      this.mapImageries = []
      this.animateMap = []
      for (const item of imageList) {
        let urlandtime = []
        const locationUrl = window.location.origin
        const envUrl = locationUrl.includes("localhost") ? "https://adc.arm.gov" : locationUrl
        const dateIndex = item.imageType === "radar" ? 2 : 1 
        if (item.imageType === "GLM") {
          urlandtime = await this.getGLMImage(envUrl+item.resourceUrl, todaysDate, item.siteName)
          this.mapImageries.push({imageName:item.imageName, imageUrl: urlandtime[0], imageFetchTime: urlandtime[1], imageExtent:item.imageExtent, imageOpacity:item.imageOpacity})
        } else if (item.imageType === "NOHRSC") {
          urlandtime = await this.getNOHRSCImage(envUrl+item.resourceUrl, todaysDate, item.siteName)
          this.mapImageries.push({imageName:item.imageName, imageUrl: urlandtime[0], imageFetchTime: urlandtime[1], imageExtent:item.imageExtent, imageOpacity:item.imageOpacity})
        } else if (item.imageType === "GIBS") {
          // Utlize GIBS API to fetch the image directly
          this.mapImageries.push({imageName:item.imageName, imageUrl: item.resourceUrl, imageFetchTime: "", imageExtent:item.imageExtent, imageOpacity:item.imageOpacity})
        } else if (item.imageType === "WMS") {
          // Utlize GIBS API to fetch the image directly
          this.mapImageries.push({imageParentLayer:item.parentLayer, imageLayer:item.layerName, imageType:item.imageType, imageName:item.imageName, imageUrl: item.resourceUrl, imageFetchTime: "", imageExtent:item.imageExtent, imageOpacity:item.imageOpacity})
        } else if (item.imageType === "GUCX") {
          // Use animateImages function to construct images names and url 
          const animateObj = await this.animateImages(envUrl+item.resourceUrl)
          this.animateMap = {imageName:item.imageName, imageList:animateObj , imageExtent:item.imageExtent, imageOpacity:item.imageOpacity}
        } else {
          urlandtime = await this.getUcarImages(
            envUrl+item.resourceUrl, todaysDate, item.siteName, item.prodName, dateIndex,
          )
          this.mapImageries.push({imageName:item.imageName, imageUrl: urlandtime[0], imageFetchTime: urlandtime[1], imageExtent:item.imageExtent, imageOpacity:item.imageOpacity})
        }
      } 
    },
    animateImages(resourceUrl) {
      // Fetching GUCX precip radar files from ARM Dev Server
      // E.g. Resource URL: https://dev.arm.gov/~kehoe/PLOTS/guc/gucxprecipradar/
      // E.g File: https://dev.arm.gov/~kehoe/PLOTS/guc/gucxprecipradar/20211124/gucxprecipradarS2.00.20211124.000310.tif 
      return axios.get(`${resourceUrl}/?C=N;O=D`, {withCredentials: true }).then(resultDates => {
        // fetcg list of all dates available at resource URL
        // Convert the HTML string into a document object
        const dateParser = new DOMParser()
        const dateDoc = dateParser.parseFromString(resultDates.data, "text/html")
        // parse the dates which are in HTML 
        let files = ""
        try {
          // using the below query selector we can get top row that has the latest date
          // If old WAF style directories then the row will be inside a <table> element
          files = dateDoc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2)")[1].querySelector("a").href
          files = files.split("/")
          return files[files.length - 2]
        } catch (error) {
          console.log(`GUCX Precip Radar Doc Query has <ul> <li> select query structure: ${error}`)
          // using the below query selector we can get top row that has the latest date
          // Else if new WAF style directories then the row will be inside a <ul> element
          files = dateDoc.querySelectorAll("ul>li")[1].querySelector("a").href
          files = files.split("/")
          return files[files.length - 2]
        }
      }).then( todaysDate => {
        // Based on recent dated directory dive one level deep to get radar files 
        return axios.get(`${resourceUrl}/${todaysDate}?C=M;O=D`).then(results => {
          // Convert the HTML string into a document object
          const parser = new DOMParser()
          const doc = parser.parseFromString(results.data, "text/html")
          // parse the files which are in HTML and only get pngs to be looped over
          let img = ""
          let totalFiles = 0
          const animateImageList = []
          if (doc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2) a[href*='png']").length !== 0) {
            totalFiles = doc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2) a[href*='png']").length
          } else {
            totalFiles = doc.querySelectorAll("ul>li a[href*='png']").length
          }
          console.log(totalFiles)
          // https://dev.arm.gov/~kehoe/PLOTS/guc/gucxprecipradar/20211124/gucxprecipradarS2.00.20211124.235748.tif
          // const example = this.convertGeoTiffToImage("https://dev.arm.gov/~kehoe/PLOTS/guc/gucxprecipradar/20211124/gucxprecipradarS2.00.20211124.235748.tif"
          // Loop through all files that are availble under this directory
          for (let i = 0 ; i < totalFiles; i++) {
            try {
              // using the below query selector we can get file url from anchor tag
              // If old WAF style directories then the row will be inside a <table> element
              img = doc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2) a[href*='png']")[i].href
            } catch (error) {
              // console.log(error)
              // using the below query selector we can get file url from anchor tag
              // Else if new WAF style directories then the row will be inside a <ul> element
              img = doc.querySelectorAll("ul>li")[i+1].querySelector("a").href
              // console.log(`Error while fetching GUCX Precip Radar URLs from image server: ${error}`)
            }
            // img - will have response something like gucxprecipradarS2.00.20211124.000310.tif
            // parse img and consruct file URL with timestamp
            const fileName = img.split("/")
            const dateTime = fileName[fileName.length-1].split(".")
            const timePattern = /(\d{2})(\d{2})(\d{2})/
            const formattedTime = dateTime[4].replace(timePattern,"$1:$2:$3")
            const latestFetchTime = `@ ${this.formattedDate(dateTime[3])}  ${formattedTime} UTC`
            let imageUrl = ""
            const imageSrc =  img.split("/")
            if (imageSrc) {
              imageUrl = `${resourceUrl}/${todaysDate}/${imageSrc[imageSrc.length-1]}`  
            }
            animateImageList.push([imageUrl, latestFetchTime])
          }
          // return urls and fetch times for the map
          return animateImageList 
        }).catch(err => {
          console.log(`Error while fetching GUCX Precip Radar URLs: ${err}`)
          return ["", "Image Unavailable"]
        })  
      }).catch(err => {
        console.log(`Error fetching GUCX Precip Radar files: ${err}`)
        return ["", "Image Unavailable"]
      })
    },
    getGLMImage(resourceUrl, currentDate, siteName) {
      // Fetching GLM GOES16 file from ARM Dev Server
      // E.g Resource URL: https://armweb-dev.ornl.gov/images/afcd/SAIL/20211202/GOES16/
      // E.g File: https://armweb-dev.ornl.gov/images/afcd/SAIL/20211202/GOES16/GOES16_1km_ir_202112012345_34.50_42.50_-113.25_-99.75_ir1_ltng16_hgwy_warn.gif
      return axios.get(`${resourceUrl}/${currentDate}/${siteName}/?C=M;O=D`)  
        .then(results => {
          // Convert the HTML string into a document object
          const parser = new DOMParser()
          const doc = parser.parseFromString(results.data, "text/html")
          // parse the files which are in HTML
          // Get the image file
          let img = ""
          try {
            // using the below query selector we can get top row that has the latest file
            // If old WAF style directories then the row will be inside a <table> element
            img = doc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2)")[1].querySelector("a").href
          } catch (error) {
            // using the below query selector we can get top row that has the latest file
            // Else if new WAF style directories then the row will be inside a <ul> element
            img = doc.querySelectorAll("ul>li")[1].querySelector("a").href
            console.log(`Error while fetching GLM image URL from image server: ${error}`)
          }
          // img - will have response something like GOES16_1km_ir_202112012345_34.50_42.50_-113.25_-99.75_ir1_ltng16_hgwy_warn.gif
          // parse img and consruct image file URL with timestamp
          const dateTime = img.split("_")
          const timePattern = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/
          const formattedTime = dateTime[3].replace(timePattern,"$4:$5")
          const latestFetchTime = `@ ${this.formattedDate(currentDate)}  ${formattedTime} UTC`
          let imageUrl = ""
          const imageSrc =  img.split("/")
          if (imageSrc) {
            imageUrl = `${resourceUrl}/${currentDate}/${siteName}/${imageSrc[imageSrc.length-1]}`  
          }
          return  [imageUrl, latestFetchTime]
        }).catch(err => {
          console.log(`Error while fetching GLM image URL: ${err}`)
          return ["", "Image Unavailable"]
        })
    },
    getNOHRSCImage(resourceUrl, currentDate, siteName) {
      // Fetching Snow Water Equivalent NOHRSC file from ARM Dev Server
      // E.g Resource URL: https://armweb-dev.ornl.gov/images/afcd/SAIL/20211202/NOHRSC/
      // E.g File: https://armweb-dev.ornl.gov/images/afcd/SAIL/20211202/NOHRSC/20211202000001.png
      return axios.get(`${resourceUrl}/${currentDate}/${siteName}/?C=M;O=D`)  
        .then(results => {
          // Convert the HTML string into a document object
          const parser = new DOMParser()
          const doc = parser.parseFromString(results.data, "text/html")
          // parse the files which are in HTML
          // Get the image file
          // const img = doc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2)")[1].querySelector("a").href
          let img = ""
          try {
            // using the below query selector we can get top row that has the latest file
            // If old WAF style directories then the row will be inside a <table> element
            img = doc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2)")[1].querySelector("a").href
          } catch (error) {
            // using the below query selector we can get top row that has the latest file
            // Else if new WAF style directories then the row will be inside a <ul> element
            img = doc.querySelectorAll("ul>li")[1].querySelector("a").href
            console.log(`Error while fetching NOHRSC image URL from image server: ${error}`)
          }
          // img - will have response something like 20211202000001.png
          // parse img and consruct image file URL with timestamp
          const fileName = img.split("/")
          const timePattern = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/
          let formattedTime = ""
          let dateTime = ""
          if (fileName) {
            dateTime = fileName[fileName.length-1].split(".")
            formattedTime = dateTime[0].replace(timePattern,"$4:$5:$6")
          }
          const latestFetchTime = `@ ${this.formattedDate(currentDate)}  ${formattedTime} UTC`
          let imageUrl = ""
          const imageSrc =  img.split("/")
          if (imageSrc) {
            imageUrl = `${resourceUrl}/${currentDate}/${siteName}/${imageSrc[imageSrc.length-1]}`  
          }
          return  [imageUrl, latestFetchTime]
        }).catch(err => {
          console.log(`Error while fetching NOHRSC image URL: ${err}`)
          return ["", "Image Unavailable"]
        })
    },
    getUcarImages (
      resourceUrl, currentDate, siteName, prodName, dateIndex,
    ) {
      // Fetching UCAR NexRAD images from ARM Dev Server
      // E.g Resource URL: https://armweb-dev.ornl.gov/images/afcd/SAIL/20211202/KGJX/CREF/
      // E.g File: https://armweb-dev.ornl.gov/images/afcd/SAIL/20211202/KGJX/CREF/KGJX_20211202_000200_CREF_black.png
      
      // Alternate file location:  
      // this is the actual location for getting recent NEXRAD images.. 
      // https://weather.ral.ucar.edu/data/radar/20210510/KHGX/CREF/?C=M;O=D
      // this gives exact url but it can be down sometimes == https://weather.ral.ucar.edu/radar/displayRad.php?icao=KHGX&prod=CREF&bkgr=gray

      return axios.get(`${resourceUrl}/${currentDate}/${siteName}/${prodName}/?C=M;O=D`)
        .then(results => {
          // Convert the HTML string into a document object
          const parser = new DOMParser()
          const doc = parser.parseFromString(results.data, "text/html")
          // parse the files which are in HTML
          // Get the image file
          let img = ""
          try {
            // using the below query selector we can get top row that has the latest file
            // If old WAF style directories then the row will be inside a <table> element
            img = doc.querySelectorAll("table:nth-of-type(1)>tbody>tr>td:nth-of-type(2)")[1].querySelector("a").href
          } catch (error) {
            // using the below query selector we can get top row that has the latest file
            // Else if new WAF style directories then the row will be inside a <ul> element
            img = doc.querySelectorAll("ul>li")[1].querySelector("a").href
            console.log(`Error while fetching Ucar image URL from image server: ${error}`)
          }
          // img - will have response something like KGJX_20211202_000200_CREF_black.png
          // parse img and consruct image file URL with timestamp
          const imageSrc =  img.split("/")
          let imageUrl = ""
          let latestFetchTime = ""
          if (imageSrc) {
            imageUrl = `${resourceUrl}/${currentDate}/${siteName}/${prodName}/${imageSrc[imageSrc.length-1]}`
            const dateTime = imageUrl.split("_")
            latestFetchTime = `@ ${this.formattedDate(currentDate)}  ${this.formattedTime(dateTime[dateIndex])} UTC`
          }
          return [imageUrl, latestFetchTime]
        }).catch(err => {
          console.log(`Error while fetching Satellite/Radar images URL for ${siteName} : ${err}`)
          return ["", "Image Unavailable"]
        })
    },
    overlayFeatures (map) {
      // initialize Popup for the map
      var container = document.getElementById("popup")
      var content = document.getElementById("popup-content")
      var closer = document.getElementById("popup-closer")
      var overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      })
      closer.onclick = function() {
        content.innerHTML = ""
        overlay.setPosition(undefined)
        closer.blur()
        return false
      }
      // function to get life zone for watershed SFA field information
      const getLifeZone = function(elevation) {
        if (elevation > 11000) {
          return "Alpine"
        } else if (elevation >= 9000) {
          return "Sub-Alpine"
        } else if (elevation >= 7000) {
          return "Montane"
        } else if (elevation >= 5600) {
          return "Foothills"
        } else if (elevation >= 0) {
          return "Plains"
        } else {
          return "N/A"
        }
      }
      // function to display field information for wateshed SFA 
      const displayFeatureInfo = function (pixel, event) {
        //eslint-disable-next-line
        const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
          return feature
        })
        if (feature) {
          if (feature.getId()) {
            overlay.setPosition(event.coordinate)
            const elevation = feature.getGeometry().getCoordinates()[2]
            //eslint-disable-next-line
            content.innerHTML = `<p><b>${feature.getId()} : ${feature.get("name")}</b></p> 
            <p> ${feature.get("research_theme")} </p> 
            <p> <b> Estimated Life Zone: </b> ${getLifeZone(elevation)} </p>
            <p> <b>Site Category: </b> ${feature.get("category")} </p>
            <p><b>Measurement Types: </b> ${feature.get("measurement_categories")}</p>
            <p> <b>Site Contact: </b> ${feature.get("contact").name} </p>`
          }
        }
      }
      //eslint-disable-next-line
      map.on("click", function (evt) {
        displayFeatureInfo(evt.pixel, evt)
      })
      map.addOverlay(overlay)
    },
    overlayKMLFeatures (map) {
      // initialize Popup for the map
      var container = document.getElementById("popup")
      var content = document.getElementById("popup-content")
      var closer = document.getElementById("popup-closer")
      var overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      })
      closer.onclick = function() {
        content.innerHTML = ""
        overlay.setPosition(undefined)
        closer.blur()
        return false
      }
      // function to display field information for wateshed SFA 
      const displayFeatureInfo = function (pixel, event) {
        //eslint-disable-next-line
        const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
          return feature
        })
        if (feature) {
          const property = feature.getProperties()
          if (property.description) {
            overlay.setPosition(event.coordinate)
            //eslint-disable-next-line
            content.innerHTML = `${property.description}`
          } else if (property.name) {
            overlay.setPosition(event.coordinate)
            //eslint-disable-next-line
            content.innerHTML = `${property.name}`
          }
        }
      }
      const hoverMouse = function (pixel, event) {
        //eslint-disable-next-line
        const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
          return feature
        })
        if (feature) {
          map.getTarget().style.cursor = "pointer"
        } else {
          map.getTarget().style.cursor = ""
        }
      }
      //eslint-disable-next-line
      map.on("click", function (evt) {
        displayFeatureInfo(evt.pixel, evt)
      })
      //eslint-disable-next-line
      map.on("pointermove", function (evt) {
        if (evt.dragging) {
          return
        }
        hoverMouse(evt.pixel, evt)
      })
      map.addOverlay(overlay)
    },
    watershedSFALayer (map) {
      const watershedGroups = new LayerGroup({
        title:"Watershed Function SFA",
        openInLayerSwitcher: true,
        layers:[],
      })
      // Initialize Popup Features on the Map to show information when cliked on Watershed icons
      this.overlayFeatures(map)

      const styleSource = function(marker) {
        return new Style({
          image: new Icon({
            src: marker,
            scale:0.1,
          }),
        })
      }
      const geoSource = function (site) {
        return new VectorSource({
          features: new GeoJSON().readFeatures({
            "type": "FeatureCollection",
            //eslint-disable-next-line
            "features":markerJSON.features.filter(function (val) {
              return val.properties.measurement_categories.includes(site.toLowerCase())
            }),
          }),
        })
      } 
      const geoJsonLayers = []
      if (this.mapWatershed.length > 0) {
        for (var i=0; i<this.mapWatershed.length; i++) {
          geoJsonLayers[i] = new VectorLayer({
            title: `${this.mapWatershed[i].siteName}`,
            visible: false,
            source: geoSource(this.mapWatershed[i].siteName),
            style: styleSource(this.mapWatershed[i].markerIcon),
          })
          watershedGroups.getLayers().push(geoJsonLayers[i])
        }//end of for
        map.getLayers().push(watershedGroups)
      }
    },
    instrumentsLayer (map) {
      const kmlGroups = new LayerGroup({
        title: "Instrument Deployments",
        openInLayerSwitcher: true,
        layers:[],
      })
      this.overlayKMLFeatures(map)
      const kmlLayers = []
      if (this.mapKmls.length > 0) {
        for (var i=0; i<this.mapKmls.length; i++) {
          kmlLayers[i] = new VectorLayer({
            title: `${this.mapKmls[i].siteName}`,
            visible: this.mapKmls[i].visible,
            source: new VectorSource({
              url:this.mapKmls[i].resourceUrl,
              format: new KML(),
            }),
          })
          kmlLayers[i].setZIndex(1)
          kmlGroups.getLayers().push(kmlLayers[i])
        }//end of for
      }
      map.getLayers().push(kmlGroups)
    },
    getTimeFromWMS (url, layerName, parentLayerName) {
      const capabilitiesParser = new WMSCapabilities()
      return fetch(url+"?service=wms&version=1.3.0&request=GetCapabilities").then(response => {
        return response.text()
      }).then(text => {
        const capabilities = capabilitiesParser.read(text)
        let layers = ""
        
        if (parentLayerName) {
          const subLayers = capabilities.Capability.Layer.Layer
          const filterLayer = subLayers.filter(el => {
            return el.Name === parentLayerName
          })
          layers = filterLayer[0].Layer
        } else {
          layers = capabilities.Capability.Layer.Layer
        }
        const layer = layers.filter(el => {
          return el.Name === layerName
        })
        const time = layer[0].Dimension[0].default
        // const time = layer[0].Dimension
        return time
      }).catch(error => {
        console.log("Error while getting time fron layer dimension. Error: " + error)
        // const formattedTime = new Date(wmsTime).toUTCString()
        // return formattedTime ? formattedTime.replace("GMT", "UTC") : ""
      })
    },
    imageryLayer (map) {
      const imageGroups = new LayerGroup({
        title: "Imagery",
        openInLayerSwitcher: true,
        layers:[],
      })
      // Load Real Time one single image
      this.singleImages(imageGroups)
      // Load Real Time WMS Tiles
      this.wmsImages(imageGroups)
      // Animate all GUCX images from todays date from the source
      this.animateGucxPrepImages(imageGroups)
      map.getLayers().push(imageGroups)
    },
    singleImages (imageGroups) {
      const imageLayers = []
      const self = this
      if (this.mapImageries.length > 0) {
        for (var i=0; i<this.mapImageries.length; i++) {
          if (this.mapImageries[i].imageType !== "WMS") {
            imageLayers[i] = new ImageLayer({
              title: `${this.mapImageries[i].imageName} <span class="layer-time">${this.mapImageries[i].imageFetchTime}</span>`,
              visible: false,
              opacity:this.mapImageries[i].imageOpacity,
              source: this.mapImageries[i].imageUrl ? new Static({
                url:this.mapImageries[i].imageUrl,
                crossOrigin: "",
                projection: "EPSG:4326",
                imageExtent:this.mapImageries[i].imageExtent,
              }) : new Static({}),
            })

            //eslint-disable-next-line
            imageLayers[i].getSource().on("imageloadstart", function() {
              self.mapLoading = true
            })
            //eslint-disable-next-line
            imageLayers[i].getSource().on("imageloadend", function() {
              self.mapLoading = false
            })
            // By default - preselect GLM image on map
            // if (this.mapImageries[i].imageName.includes("GUCX")) {
            //   this.mapImageries[i].imageUrl? imageLayers[i].setVisible(true): imageLayers[i].setVisible(false)
            // }
            imageGroups.getLayers().push(imageLayers[i])
          }
          
        }//end of for
      }
    },
    async wmsImages (imageGroups) {
      const imageLayers = []
      const self = this
      if (this.mapImageries.length > 0) {
        const wmsImageries = this.mapImageries.filter(el => {
          return el.imageType === "WMS"
        })
        for (var i=0; i<wmsImageries.length; i++) {
          let wmsSource = new TileWMS({})
          let wmsTime = ""
          if (wmsImageries[i].imageParentLayer == "" || wmsImageries[i].imageParentLayer == "Geostationary") {
            wmsTime = await this.getTimeFromWMS(wmsImageries[i].imageUrl, wmsImageries[i].imageLayer, wmsImageries[i].imageParentLayer)
            if (wmsTime) {
              const formattedTime = new Date(wmsTime).toUTCString()
              wmsTime = formattedTime.replace("GMT", "UTC")
            }
            wmsSource = new TileWMS({
              url: wmsImageries[i].imageUrl,
              params: {LAYERS: wmsImageries[i].imageLayer, SRS:"EPSG:4326", WIDTH:512, HEIGHT:512},
              serverType: "geoserver",
              transition: 0,
              crossOrigin:"anonymous",
            })
          } else {
            let date = this.getCurrentDate()
            let convertedDate = ""
            // show previous day image for MODIS layers
            if (wmsImageries[i].imageName.includes("MODIS")) {
              // set previous day date
              date = this.getCurrentDate(1)
              convertedDate = date.replace(/(\d{4})(\d{2})(\d{2})/,"$1-$2-$3")+`T00:00:00Z`
              wmsTime = this.formattedDate(date)
            } else {
              // set previous hour date 
              const hourAgoTime = new Date(new Date().getTime() - (1000*60*60))
              const hourAgoHrs = hourAgoTime.getUTCHours()
              convertedDate = date.replace(/(\d{4})(\d{2})(\d{2})/,"$1-$2-$3")+`T${hourAgoHrs}:00:00Z`
              const formattedTime = new Date(convertedDate).toUTCString()
              wmsTime = formattedTime.replace("GMT", "UTC")
            }
            wmsSource = new TileWMS({
              url: `${wmsImageries[i].imageUrl}?TIME=${convertedDate}`,
              params: {LAYERS: wmsImageries[i].imageLayer, SRS:"EPSG:4326", WIDTH:512, HEIGHT:512},
              serverType: "geoserver",
              transition: 0,
            })
          }
      
          imageLayers[i] = new TileLayer({
            title: `${wmsImageries[i].imageName} ${wmsTime?`<span class="layer-time">@${wmsTime}</span>`:""}`,
            visible: false,
            opacity:wmsImageries[i].imageOpacity,
            source: wmsSource,
          })

          //eslint-disable-next-line
          imageLayers[i].getSource().on("tileloadstart", function() {
            self.mapLoading = true
          })
          //eslint-disable-next-line
          imageLayers[i].getSource().on("tileloadend", function() {
            self.mapLoading = false
          })
          // By default - preselect GLM image on map
          // if (this.mapImageries[i].imageName.includes("GUCX")) {
          //   this.mapImageries[i].imageUrl? imageLayers[i].setVisible(true): imageLayers[i].setVisible(false)
          // }
          imageGroups.getLayers().push(imageLayers[i])
        }//end of for
      }
    },
    async convertGeoTiffToImage (url) {
      // This method converts a .tif file to .png image file
      // We are converting .tif to .png image file so we can overlay images on the Map
      let width, height, canvas = ""
      // fetch remote .tif url and convert it to arrayBuffer via geotiff library
      const dataUrl = fetch(url).then( response => {
        return response.arrayBuffer()
      }).then(arrayBuffer => {
        // open the .tif fie so we can inspect it
        return fromArrayBuffer(arrayBuffer)
      }).then(tiff => {
        // The TIFF is structured in a small header and a list of one or more images (Image File Directory, IFD to use the TIFF nomenclature). 
        // To get one image by index the getImage() function must be used. 
        return tiff.getImage()
      }).then(image => {
        // Now that we have obtained a GeoTIFFImage object we can inspect its metadata (like size, tiling, number of samples, geographical information, etc.). 
        // All the metadata is parsed once the IFD is first parsed, thus the access to that is synchronous:
        width = image.getWidth()
        height = image.getHeight()
        // To read a whole image into one big array of arrays
        // geotiff.js provides a method to automatically convert these images to RGB: readRGB().
        return image.readRGB()
      }).then(rgb => {
        //create an offscreen canvas for rendering
        canvas = document.createElement("canvas")
        canvas.width = width
        canvas.height = height
        // Set  height and width of canvas recived from image
        // create a 2d context..we can also us Plotty package to copy rgb data to create a data URL 
        const context = canvas.getContext("2d")
        const data = context.getImageData(0, 0, width, height)
        const rgba = data.data
        let j = 0
        for (let i = 0; i < rgb.length; i += 3) {
          rgba[j] = rgb[i]
          rgba[j + 1] = rgb[i + 1]
          rgba[j + 2] = rgb[i + 2]
          rgba[j + 3] = 255
          j += 4
        }
        // put Image data in the context 
        context.putImageData(data, 0, 0)
        // Return .png image as output so the map can overlay
        return canvas.toDataURL("image/png")
      })
      return dataUrl
    },
    animateGucxPrepImages (imageGroups) {
      let animateImageLayers = []
      const self = this
      if (this.animateMap.imageList && this.animateMap.imageList[0]) {
        if (this.animateMap.imageList[0].length > 0) {
          // let imageUrl = ""
          // if (this.animateMap.imageList[0][0]) {
          //   imageUrl = await this.convertGeoTiffToImage(this.animateMap.imageList[0][0])
          // }          
          animateImageLayers = new ImageLayer({
            title: `${this.animateMap.imageName} <span id="csu" class="layer-time">${this.animateMap.imageList[0][1]}</span>`,
            visible: false,
            opacity:this.animateMap.imageOpacity,
            source: new Static({
              url: this.animateMap.imageList[0][0],
              crossOrigin: "",
              projection: "EPSG:4326",
              imageExtent:this.animateMap.imageExtent,
            }),
          })
          var animateInterval = ""
          //eslint-disable-next-line
          animateImageLayers.on("propertychange", function(value) {
            if (value.key === "visible" && value.oldValue === true) {
              // when image is unselected, stop the animation and clear it 
              clearInterval(animateInterval)
            }
            if (value.key === "visible" && value.oldValue === false) {
              // when image is selected, start the animation
              var i = self.animateMap.imageList.length - 1
              //eslint-disable-next-line
              animateInterval =  setInterval ( async function () {
                if (i > 0) {
                  // console.log(self.animateMap.imageList[i][1])
                  // const imageUrl = await self.convertGeoTiffToImage(self.animateMap.imageList[i][0])
                  var source = new Static({
                    url: self.animateMap.imageList[i][0],
                    crossOrigin: "",
                    projection: "EPSG:4326",
                    imageExtent:self.animateMap.imageExtent,
                  })
                  var csuTime = document.getElementById("csu")
                  csuTime.innerHTML = `${self.animateMap.imageList[i][1]}`
                  // animateImageLayers.set("title", `${self.animateMap.imageName} <span class="layer-time">${self.animateMap.imageList[i][1]}</span>`, true)
                  animateImageLayers.setSource(source)
                  i--
                } else {
                  i = self.animateMap.imageList.length - 1
                }
              }, 1500)
            }
          })
          //eslint-disable-next-line
          animateImageLayers.getSource().on("imageloadstart", function() {
            self.mapLoading = true
          })
          //eslint-disable-next-line
          animateImageLayers.getSource().on("imageloadend", function() {
            self.mapLoading = false
          })
          //eslint-disable-next-line
          animateImageLayers.on("change:visible", (e) => {
            // clear image source if image not visible 
            if (e.oldValue === true) {
              animateImageLayers.setSource(new Static({}))
            }
          })
          // }//end of for
          // By default - preselect GUCX image on map
          if (this.animateMap.imageName.includes("GUCX")) {
            this.animateMap.imageList? animateImageLayers.setVisible(true): animateImageLayers.setVisible(false)
          }
          imageGroups.getLayers().push(animateImageLayers)
        } else {
          animateImageLayers = new ImageLayer({
            title: `${this.animateMap.imageName} <span id="csu" class="layer-time">${this.animateMap.imageList[1]}</span>`,
            visible: false,
          })
          imageGroups.getLayers().push(animateImageLayers)
        }
      }
    },
    exportMap (map) {
      map.once("rendercomplete", () => {
        const mapCanvas = document.createElement("canvas")
        const size = map.getSize()
        mapCanvas.width = size[0]
        mapCanvas.height = size[1]
        const mapContext = mapCanvas.getContext("2d")
        Array.prototype.forEach.call(map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),(canvas) => {
          if (canvas.width > 0) {
            const opacity = canvas.parentNode.style.opacity || canvas.style.opacity
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity)
            let matrix
            const transform = canvas.style.transform
            if (transform) {
              // Get the transform parameters from the style"s transform matrix
              matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(",").map(Number)
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ]
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(mapContext,matrix)
            const backgroundColor = canvas.parentNode.style.backgroundColor
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor
              mapContext.fillRect(0, 0, canvas.width, canvas.height)
            }
            mapContext.drawImage(canvas, 0, 0)
          }
        })
        mapContext.globalAlpha = 1
        mapContext.setTransform(
          1, 0, 0, 1, 0, 0,
        )
        const snapshotFileName = this.getCampaignAcronym && this.getCurrentDate() ? this.getCampaignAcronym+"_"+this.getCurrentDate()+".png" : "map_screenshot.png"
        mapCanvas.toBlob((blob) => {
          saveAs(blob, snapshotFileName)
        })
      })
      map.renderSync()
    },
    initializeMap() {
      const self = this
      // this is where we create the OpenLayers map and adding a background tiled layer
      const raster = new TileLayer({
        title:"OSM",
        baseLayer:true,
        displayInLayerSwitcher: false,
        source: new OSM({
          crossOrigin:"anonymous",
        }), // tiles are served by OpenStreetMap
      })

      // this is where we create openLayers Map
      const map = new Map({
        // the map will be created using the "map-root" ref
        target: this.$refs["map-root"], // "map-root",
        layers: [raster],
        // the map view will initially show the whole world
        view: new View({
          projection:"EPSG:4326",
          zoom: this.mapZoom,
          center: transform(this.mapCenter, "EPSG:4326", "EPSG:4326"), //[-95.283222, 29.903535]
        }),
      })
      
      // Initialize WaterShed Function over the Map
      this.watershedSFALayer(map)
      // Initialize KMLs (Instruments Location) over the Map
      this.instrumentsLayer(map)
      // Initialize Imagery as Layers over the Map
      this.imageryLayer(map)

      const layerSwitcher = new LayerSwitcher({
        title:"Map Layers",
        reordering:false,
      })
      map.addControl(layerSwitcher)
      // Add a save button with on active event
      const snapshotButton = new Button ({
        html: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16"><path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/><path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0"/></svg>',
        className: "save",
        title: "Take a Snapshot!",
        handleClick: () => {
          // console.log("Center: "+map.getView().getCenter()+" - zoom: "+map.getView().getZoom())
          this.exportMap(map) // capture a snapshot of the map
        },
      })
      map.addControl ( snapshotButton )
      try {
        // By default - show LayerSwitcher open
        layerSwitcher.show()
      } catch (error) {
        console.log("Exception with LayerSwitcher: "+error)
      }
      this.mapLoading = false
    },
  },
}
</script>

<template>
  <div v-if="isIFrame">
    <Spinner v-if="mapLoading" text="Loading map ..."/>
    <iframe class="iFrame" :src="iFrameSrc" referrerpolicy="strict-origin-when-cross-origin" allow="fullscreen" frameborder="0" scrolling="no" />
  </div>
  <div v-else id="map-root" ref="map-root" style="width: 100%; height: 100%;">
    <Spinner v-if="mapLoading" text="Loading map ..." class="spinOverMap"/>
    <div v-show="!mapLoading" id="popup" class="ol-popup">
      <a id="popup-closer" href="#"  class="ol-popup-closer"/>
      <div id="popup-content"/>
    </div>
  </div>
</template>

<style>
#app{
   display: grid;
}
.ol-layer canvas{
  max-width: none !important;
  font-style: none !important;
  vertical-align: none !important;
}
.layer-time{
  font-size: 75%;
  font-weight: bold;
  display: block;
}
/* manually changing ol-switcher css */
.ol-layerswitcher > ul.panel {
  width:30rem;
}
.ol-layerswitcher li .li-content label {
  max-width: none;
}
.ol-layerswitcher .panel li label{
  height:2.4em;
  white-space: none !important;
}
.ol-layerswitcher .panel li label span{
  display: block !important;
}
.ol-layerswitcher .panel{
  overflow: scroll !important;
}
.ol-control.ol-layerswitcher{
  max-height: calc(100% - 18em) !important;
}
.iFrame {
  min-width: 100vw;
  min-height: calc(100vh - 85px - 22px - 40px - 112px);
}
.ol-zoom {
  left: unset;
  right: 8px;
}
.ol-control{
  background-color: none;
}
.ol-control button{
  height: 40px;
  width: 40px;
  outline: none;
  background-color: rgba(0,0,0,0.6);
  margin: 0;
}
.ol-control .ol-zoom-in{
  font-size: 1.5em!important;
}
.ol-control .ol-zoom-out{
  font-size: 1.5em!important;
}
.ol-control.ol-layerswitcher{
  top:5.5em;
  padding-top: 0px;
}
.ol-layerswitcher button{
  background-color: rgba(0,0,0,0.6);
}
.ol-control button:hover{
  background-color: black;
}
.ol-control button:focus{
  background-color: rgba(0,0,0,0.6);
}
.ol-layerswitcher button:hover{
  background-color:black;
}
.popover_content{
  position: absolute;
  bottom: 10rem;
  left: 1;
  background: white;
  z-index: 9999;
}
.popover_content p {
  padding:1px;
}
.ol-popup {
  font-family: 'Lucida Grande', Verdana, Geneva, Lucida, Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 300px;
}
.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}
.ol-popup-closer:after {
    content: "✖";
    font-size: 16px !important;
    color: #c3c3c3;
}
.spinOverMap{
  margin-right: 20%;
}
.spinOverMap .spinnerWrapper{
  z-index: 9999;
  position: absolute;
}
</style>
