<script>
import { EventBus } from "@/event-bus.js"
import { mapGetters } from "vuex"
import Config from "/config.js"
import store from "@/store/index.js"
import global from "@/mixins/global.js"
import axios from "axios"
import VueRecaptcha from "vue-recaptcha"
import Back from "@/components/Back"
import Form from "@/components/Form"

export default {
  components: {
    VueRecaptcha,
    Back,
    Form,
  },
  mixins: [
    global,
  ],
  data: () => ({
    reportInfo: {},
    recaptchaVerified: false,
    recaptchaResponse: null,
  }),
  computed: {
    ...mapGetters("global", ["getCampaignId", "getCampaignAcronym"]),
    isEdit() {
      return this.$route.params && this.$route.params.reportId
    },
    reportTypeId() {
      return this.reportInfo && this.reportInfo.reportTypeId ?
        this.reportInfo.reportTypeId :
        null
    },
    reportTypeName() {
      return this.reportInfo && this.reportInfo.reportTypeName ?
        this.reportInfo.reportTypeName.charAt(0).toUpperCase() + this.reportInfo.reportTypeName.slice(1) :
        ""
    },
    form() {
      return this.reportInfo && this.reportInfo.reportSchema ?
        this.reportInfo.reportSchema :
        null
    },
    route() {
      return this.$route.params.reportType?.toString()
    },
    recaptchaKey() {
      return Config.google.recaptchaKey
    },
  },
  watch: {
    route(value) {
      this.$emit("formContextUpdated")
    },
  },
  mounted() {
    if (this.isEdit) {
      const reportId = this.$route.params.reportId
      this.$store.dispatch("reports/getReport", reportId).then(response => {
        this.reportInfo = response?.data?.data?.allCatalogReports?.nodes?.[0]?.catalogReportTypeByReportTypeId
        const report = response?.data?.data?.allCatalogReports?.nodes?.[0]
        if (report) {
          this.loadReport(report)
        }
      })
    } else {
      const reportType = this.$route.params.reportType
      this.$store.dispatch("reports/getReportInfo", reportType).then(response => {
        this.reportInfo = response?.data?.data?.allCatalogReportTypes?.nodes?.[0]
        this.clearForm()
        this.initializeForm()
      })
    }
  },
  methods: {
    setProcessing(value) {
      this.$store.dispatch("global/setProcessing", value)
    },
    goToReports() {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/reports`,
      })
    },
    initializeForm() {
      const form = this.reportInfo?.reportSchema
      if (form) {
        form.forEach(row => {
          row.items.forEach(item => {
            if (item.data.id === "reporter") {
              item.data.value = store.state.AuthService.personName
            } else if (item.data.id === "reportDate") {
              item.data.value = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss")
            } else if (item.data.id === "history") {
              item.data.user = store.state.AuthService.personName
            } else if (item.type === "attachment") {
              item.data.downloadBaseUrl = Config.fieldCampaignDashboardFileApi.host
            }
          })
        })
      }
    },
    clearForm() {
      const form = this.reportInfo?.reportSchema
      if (form) {
        form.forEach(row => {
          row.items.forEach(item => {
            if (item.hidden === false) {
              item.hidden = true
            }
            if (item.data.id !== "reporter") {
              if (Array.isArray(item.data.default)) {
                item.data.value = []
              } else {
                item.data.value = item.data.default
              }
            }
          })
        })
      }
    },
    submitReport() {
      const message = !this.isEdit ?
        `Submit new ${this.reportTypeName} report for ${this.getCampaignAcronym.toUpperCase()}?` :
        `Update existing report for ${this.getCampaignAcronym.toUpperCase()}?`
      this.showMsgBoxConfirm(message).then(choice => {
        if (choice) {
          this.setProcessing(true)

          const formattedReport = {}
          const previousFields = {}
          this.form.forEach(row => {
            row.items.forEach(item => {
              formattedReport[item.data.id] = item.data.value
              if (item.data.previous) {
                previousFields[item.data.id] = item.data.previous
              }
            })
          })

          let catalogReport = {}
          if (this.isEdit) {
            catalogReport = formattedReport
          } else {
            catalogReport["campaignId"] = this.getCampaignId
            catalogReport["personId"] = store.state.AuthService.personId
            catalogReport["reportTypeId"] = this.reportTypeId
            catalogReport["planDetails"] = formattedReport.planDetails
            catalogReport["shortDescription"] = formattedReport.shortDescription
            catalogReport["reportDate"] = formattedReport.reportDate
          }

          const catalogReportMethod = this.isEdit ? "updateCatalogReport" : "createCatalogReport"
          this.$store.dispatch(`reports/${catalogReportMethod}`, catalogReport).then(response => {
            const reportId = this.isEdit ?
              response?.data?.data?.updateCatalogReportByReportId?.catalogReport?.reportId :
              response?.data?.data?.createCatalogReport?.catalogReport?.reportId

            if (reportId !== null) {
              const promises = []

              const catalogAttachments = this.formatCatalogAttachment(formattedReport, reportId)

              // FIXME - attachment delete logic
              /*
              const currentAttachmentNames = catalogAttachments
              .map(currentAttachment => currentAttachment.file.name)

              const previousAttachments = previousFields.attachment
              if (previousAttachments && Array.isArray(previousAttachments) && previousAttachments.length > 0) {
                previousAttachments.forEach(attachment => {
                  if (!currentAttachmentNames.includes(attachment.file.name)) {
                    const payload = {
                      reportId,
                      attachment,
                    }
                    promises.push(this.$store.dispatch("reports/deleteCatalogAttachment", payload))
                  }
                })
              }
               */

              if (catalogAttachments && Array.isArray(catalogAttachments) && catalogAttachments.length > 0) {
                catalogAttachments.forEach(catalogAttachment => {
                  if (!catalogAttachment.exists) {
                    promises.push(this.$store.dispatch("reports/createCatalogAttachment", catalogAttachment))
                  }
                })
              }

              const catalogComments = this.formatCatalogComment(formattedReport, reportId)
              if (catalogComments && Array.isArray(catalogComments) && catalogComments.length > 0) {
                catalogComments.forEach(catalogComment => {
                  if (!catalogComment.commentId) {
                    promises.push(this.$store.dispatch(`reports/createCatalogComment`, catalogComment))
                  }
                })
              }

              const catalogEmails = this.formatCatalogEmail(formattedReport, reportId)

              const currentEmailIds = catalogEmails
                .filter(currentEmail => currentEmail.emailId)
                .map(currentEmail => currentEmail.emailId)

              const previousEmailIds = previousFields.email.map(currentEmail => currentEmail.emailId)
              if (previousEmailIds && Array.isArray(previousEmailIds) && previousEmailIds.length > 0) {
                previousEmailIds.forEach(previousEmailId => {
                  if (!currentEmailIds.includes(previousEmailId)) {
                    promises.push(this.$store.dispatch("reports/deleteCatalogEmail", previousEmailId))
                  }
                })
              }

              if (catalogEmails && Array.isArray(catalogEmails) && catalogEmails.length > 0) {
                catalogEmails.forEach(catalogEmail => {
                  if (!catalogEmail.emailId) {
                    promises.push(this.$store.dispatch("reports/createCatalogEmail", catalogEmail))
                  }
                })
              }

              // TODO - make this generic
              if (this.getCampaignAcronym === "cape-k") {
                const payload = {
                  reportId,
                  lowLevelClouds: formattedReport.lowLevelClouds,
                  midLevelClouds: formattedReport.midLevelClouds,
                  highLevelClouds: formattedReport.highLevelClouds,
                  precipitation: formattedReport.precipitation,
                  meteorologicalPhenomena: formattedReport.meteorologicalPhenomena,
                  weatherNotes: formattedReport.weatherNotes,
                  instrumentOperationalNotes: formattedReport.instrumentOperationalNotes,
                }

                const additionalFieldsMutation = this.isEdit ?
                  "updateCatalogAdditionalFields" :
                  "createCatalogAdditionalFields"
                promises.push(this.$store.dispatch(`reports/${additionalFieldsMutation}`, payload))
              }

              Promise.all(promises).then(() => {
                const payload = {
                  reportId,
                  formattedReport,
                  recaptchaResponse: this.recaptchaResponse,
                }
                this.$store.dispatch("reports/sendEmailNotifications", payload).then(() => {
                  this.setProcessing(false)
                  const message = "Report submitted."
                  this.showMsgBoxOk(message).then(() => {
                    this.goToReports()
                  })
                }).catch(() => {
                  this.setProcessing(false)
                  const message = "An error occurred during submission."
                  this.showMsgBoxOk(message).then(() => {
                    this.goToReports()
                  })
                })
              }).catch(() => {
                this.setProcessing(false)
                const message = "An error occurred during submission."
                this.showMsgBoxOk(message).then(() => {
                  this.goToReports()
                })
              })
            } else {
              this.setProcessing(false)
            }
          }).catch(() => {
            this.setProcessing(false)
          })
        }
      })
    },
    loadReport(payload) {
      this.form.forEach(row => {
        row.items.forEach(item => {
          if (item.hidden) {
            item.hidden = false
          }

          if (item.data.id === "reportId") {
            item.data.value = payload.reportId
          } else if (item.data.id === "lastUpdatedBy") {
            // if reportUpdated nameFirst, nameLast = {null, null} then use reporter personByPersonId nameFirst, nameLast
            // reason reportUpdated name shows {null, null} for some records because they were manually updated in the DB and don't have a personId assigned.
            if (payload.reportUpdated.nameFirst && payload.reportUpdated.nameLast) {
              item.data.value = `${payload.reportUpdated.nameFirst} ${payload.reportUpdated.nameLast}`
            } else {
              item.data.value = `${payload.personByPersonId.nameFirst} ${payload.personByPersonId.nameLast}`
            }
          } else if (item.data.id === "lastUpdatedDate") {
            item.data.value = this.$moment.utc(payload.reportUpdated.datetime).format("YYYY-MM-DD HH:mm:ss")
          } else if (item.data.id === "reportType") {
            item.data.value = payload.catalogReportTypeByReportTypeId.reportTypeDesc
          } else if (item.data.id === "reporter") {
            const person = payload.personByPersonId
            item.data.value = `${person.nameFirst} ${person.nameLast}`
          } else if (item.data.id === "reportDate") {
            item.data.value = this.$moment.utc(payload.reportDate).format("YYYY-MM-DD HH:mm:ss")
          } else if (item.data.id === "email") {
            item.data.value = payload
              .catalogEmailsByReportId
              .nodes
              .map(email => {
                const person = email.personByPersonId
                return {
                  emailId: email.emailId,
                  label: `${person.nameFirst} ${person.nameLast} (${person.email})`,
                  value: person.personId,
                }
              })
            item.data.previous = item.data.value
          } else if (item.data.id === "planDetails") {
            item.data.value = payload.planDetails
          } else if (item.data.id === "shortDescription") {
            item.data.value = payload.shortDescription
          } else if (item.data.id === "attachment") {
            item.data.value = []
            const reportId = payload.reportId
            axios({
              method: "get",
              url: `${Config.fieldCampaignDashboardFileApi.host}/list/${reportId}`,
              withCredentials: true,
            }).then(response => {
              const totalFiles = response?.data?.length
              let processed = 0
              if (totalFiles > 0) {
                this.setProcessing(true)
              }
              response.data.forEach(fileUrl => {
                const fileName = fileUrl.substr(fileUrl.lastIndexOf("/") + 1)
                const url = `${Config.fieldCampaignDashboardFileApi.host}${fileUrl}`
                axios({
                  method: "get",
                  url,
                  params: {
                    fileRetrieve: true,
                  },
                  responseType: "blob",
                  withCredentials: true,
                  // Accept all headers for now..
                  // headers: {
                  //   Accept: "image/png",
                  // },
                }).then(fileResponse => {
                  const fileObject = {
                    file: new File([fileResponse.data], fileName, {type: "image/png"}),
                    uri: fileUrl,
                    exists: true,
                  }
                  item.data.value.push(fileObject)
                  item.data.previous.push(fileObject)
                  processed++
                  if (totalFiles > 0 && processed == totalFiles) {
                    this.setProcessing(false)
                    const attachmentName = this.$route?.query?.attachment
                    if (attachmentName) {
                      EventBus.$emit("autoDownloadAttachment", attachmentName)
                    }
                  }
                })
              })
            })
          } else if (item.data.id === "history") {
            item.data.user = store.state.AuthService.personName
            item.data.value = payload
              .catalogCommentsByReportId
              .nodes
              .map(comment => {
                const person = comment.personByPersonId
                return {
                  commentId: comment.commentId,
                  user: `${person.nameFirst} ${person.nameLast}`,
                  type: "comment",
                  icon: "chat-square-quote",
                  date: this.$moment.utc(comment.commentDate).format("YYYY-MM-DD HH:mm:ss"),
                  description: comment.commentText,
                }
              })
          } else if (item.data.id === "lowLevelClouds") {
            item.data.value = payload?.catalogAdditionalFieldByReportId?.lowLevelClouds
          } else if (item.data.id === "midLevelClouds") {
            item.data.value = payload?.catalogAdditionalFieldByReportId?.midLevelClouds
          } else if (item.data.id === "highLevelClouds") {
            item.data.value = payload?.catalogAdditionalFieldByReportId?.highLevelClouds
          } else if (item.data.id === "meteorologicalPhenomena") {
            item.data.value = payload?.catalogAdditionalFieldByReportId?.meteorologicalPhenomena
          } else if (item.data.id === "instrumentOperationalNotes") {
            item.data.value = payload?.catalogAdditionalFieldByReportId?.instrumentOperationalNotes
          } else if (item.data.id === "precipitation") {
            item.data.value = payload?.catalogAdditionalFieldByReportId?.precipitation
          } else if (item.data.id === "weatherNotes") {
            item.data.value = payload?.catalogAdditionalFieldByReportId?.weatherNotes
          }
        })
      })
    },
    cancelReport() {
      const message = "Are you sure you want to cancel? All changes will be lost."
      this.showMsgBoxConfirm(message).then(choice => {
        if (choice) {
          store.dispatch(`${this.getCampaignAcronym}/clearForm`)
          this.$router.go(-1)
        }
      })
    },
    recaptchaVerify(recaptchaResponse) {
      this.recaptchaResponse = recaptchaResponse
      this.recaptchaVerified = true
    },
    recaptchaExpired() {
      this.recaptchaResponse = null
      this.recaptchaVerified = false
    },
  },
}
</script>

<template>
  <div>
    <Back
      :emit="true"
      @goBack="cancelReport"
    />
    <b-container class="pb-3 mb-5">
      <b-row>
        <b-col cols="8">
          <h2
            class="field-reports font-weight-bold pointer"
            @click="goToReports"
          >
            Field Reports
          </h2>
          <h1 class="font-weight-bold">
            {{ reportTypeName }}
          </h1>
        </b-col>
      </b-row>

      <Form
        v-if="form"
        v-model="form"
      />

      <b-row no-gutters>
        <b-col>
          <VueRecaptcha
            class="float-right mb-3"
            :sitekey="recaptchaKey"
            :loadRecaptchaScript="true"
            @verify="recaptchaVerify"
            @expired="recaptchaExpired"
          />
        </b-col>
      </b-row>

      <b-row class="text-right">
        <b-col>
          <b-link
            class="mr-3"
            @click="cancelReport"
          >
            Cancel
          </b-link>
          <b-btn
            :disabled="!recaptchaVerified"
            class="background-blue"
            squared
            @click="submitReport"
          >
            {{ isEdit ? "Update" : "Submit" }}
          </b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
</style>
