<script>
import Day from "./CalendarDay"
import { EventBus } from "@/event-bus.js"

export default {
  components: {
    Day,
  },
  props:{
    week: {
      type: Array,
    },
  },
  data () {
    return {
      showWeekNumberFlag: false,
    }
  },
  created() {
    const self = this
    EventBus.$on("WEEK_SELECTED", (payload) => {
      if (payload.weekDate != self.week[0].date) {
        self.showWeekNumberFlag = false
      }
    })
  },
  methods : {
    showWeekNumber() {
      const self = this
      self.showWeekNumberFlag = true
      EventBus.$emit("WEEK_SELECTED", {weekDate:self.week[0].date})
    },
  },
}
</script>
<template>
  <div class="week-row" @click="showWeekNumber">
    <div v-if="showWeekNumberFlag" class="week-number">{{ "Week" }} {{week[0].date.format('w')}}</div>
    <Day
      v-for="(day, index) in week"
      :key="index"
      :day="day"
    />
  </div>
</template>
<style>
.week-row {
    width: 100%;
    border-left: 1px solid #e0e0e0;
    display: flex;
    cursor: pointer;
}
.week-number {
    border: 1px solid #e1e1e1;
    border-right: 0px;
    border-radius: 5px 0 0 5px;
    background-color: #fafafa;
    text-align: right;
    position: absolute;
    /* width: 70px; */
    /* left: -71px; */
    /* padding: 8px 5px; */
    cursor: pointer;
}
</style>
