<script>
import moment from "moment"
import { mapState, mapGetters } from "vuex"
import { EventBus } from "@/event-bus.js"

export default {
  props : {
    currentMonth : {},
  },
  computed: {
    ...mapGetters("global", ["getCampaignId", "getCampaignAcronym", "getCampaignSiteCode"]),
    ...mapState("plots", ["plots"]),
    title () {
      if (!this.currentMonth) return
      return this.currentMonth.format("MMMM YYYY")
    },
  },
  mounted() {
    // this.goToday()
    // this.calendarContextUpdated(this.currentMonth)
    // EventBus.$emit("updateCalendarEvents", payload)
  },
  methods : {
    goPrev () {
      const payload = moment(this.currentMonth).subtract(1, "months").startOf("month")
      // this.$root.$emit(CHANGE_MONTH, payload)
      EventBus.$emit("CHANGE_MONTH", payload)
      // this.calendarContextUpdated(payload)
      EventBus.$emit("updateCalendarEvents", payload)
    },
    goNext () {
      const payload = moment(this.currentMonth).add(1, "months").startOf("month")
      // this.$root.$emit(CHANGE_MONTH, payload)
      // this.calendarContextUpdated(payload)
      EventBus.$emit("CHANGE_MONTH", payload)
      EventBus.$emit("updateCalendarEvents", payload)
    },
    goToday () {
      // this.$root.$emit(CHANGE_MONTH, moment())
      // this.calendarContextUpdated(moment())
      EventBus.$emit("CHANGE_MONTH", moment())
      EventBus.$emit("updateCalendarEvents", moment())
            
    },
  },
}
</script>
<template>
  <b-row>
    <b-col cols="9" class="text-center">
      <b-button-group>
        <b-button @click.stop="goPrev">&lArr; Previous</b-button>
        <b-button @click.stop="goToday">&dArr; Today</b-button>
        <b-button @click.stop="goNext">Next &rArr;</b-button>
      </b-button-group>
    </b-col>
    <b-col cols="3">
      <div class="title"> {{title}}</div>
    </b-col>
  </b-row>
</template>
<style>
.full-calendar-header{
    display: flex;
    align-items: center;
}
.header-center{
    flex:3;
    text-align:center;
}
.title {
    text-align: center;
    font-size: 1.5em;
    font-weight: bolder;
}
</style>
